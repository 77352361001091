import React, { useEffect, useState } from "react";
import PageLocation from "../../../components/PageLocation";
import etfFee from "../../../assets/etfFee.png";
import playStore from "../../../assets/playStore.png";
import appStore from "../../../assets/appStore.png";
import waranTerstruktur from "../../../assets/waranTerstruktur.png";
import FollowSocmed from "../../../components/FollowSocmed";
import { useNavigate, useLocation } from "react-router-dom";
import Aos from "aos";
import { storageLocation } from "../../../config/server";
import swCode from "../../../assets/swCode.png";
import faqArrow from "../../../assets/faqArrow.png";
import { server } from "../../../config/server";
import moment from "moment";
import { get } from "jquery";
import bondsBanner from "../../../assets/bondsBanner.png";
export function Bonds({}) {
  const { state } = useLocation();
  let toAnnouncement = false;
  const [data, setData] = useState([]);
  const [newSwData, setNewSwData] = useState([]);
  const [expiredSwData, setExpiredSwData] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [offset, setOffset] = useState(0);
  // console.log(state);
  if (state) {
    toAnnouncement = state.toAnnouncement;
  }

  useEffect(() => {
    Aos.init({ duration: 2000 });
    getData();
  }, [sortBy]);
  useEffect(() => {
    // console.log(
    //   document
    //     .getElementById(`tabel_data_waran_wrapper`)
    //     .getBoundingClientRect().width
    // ,window.innerWidth);
    // if (window.innerWidth >= 1660) {
    //   const onScroll = () => setOffset(window.pageYOffset);
    //   // clean up code
    //   window.removeEventListener("scroll", onScroll);
    //   window.addEventListener("scroll", onScroll, { passive: true });
    //   // console.log(
    //   //   document.documentElement.offsetHeight,
    //   //   "hilih",
    //   //   document.getElementById(`data_waran_end`).getBoundingClientRect()
    //   // );
    //   // console.log( document.getElementById(`th_waran_top`), document
    //   // .getElementById(`tabel_data_waran_wrapper`)
    //   // .getBoundingClientRect().width);
    //   document.getElementById(`th_waran_top`).style.width = document
    //     .getElementById(`tabel_data_waran_wrapper`)
    //     .getBoundingClientRect().width;
    //   document.getElementById(`th_waran_top`).style.width = document
    //     .getElementById(`tabel_data_waran_wrapper`)
    //     .getBoundingClientRect().width;
    //   var tableHeadTop = document.getElementById(`th_waran_top`);
    //   var startShow = document
    //     .getElementById(`th_waran_static`)
    //     .getBoundingClientRect();
    //   var navbarH = document
    //     .getElementById(`navBar`)
    //     .getBoundingClientRect().height;
    //   var subpathH = document
    //     .getElementById(`subPath`)
    //     .getBoundingClientRect().height;
    //   var tableEnd = document
    //     .getElementById(`data_waran_end`)
    //     .getBoundingClientRect();
    //   // console.log(startShow, navbarH, subpathH);
    //   tableHeadTop.style.top = `${navbarH + subpathH}px`;
    //   document.getElementById(`th_waran_top`).style.width = `${
    //     document
    //       .getElementById(`tabel_data_waran_wrapper`)
    //       .getBoundingClientRect().width
    //   }px`;
    //   if (
    //     startShow.top <= navbarH + subpathH &&
    //     tableEnd.top >
    //       navbarH +
    //         subpathH +
    //         document.getElementById(`th_waran_static`).getBoundingClientRect()
    //           .height
    //   ) {
    //     tableHeadTop.style.display = "block";
    //     tableHeadTop.style.top = navbarH + subpathH;
    //   } else {
    //     tableHeadTop.style.display = "none";
    //   }
    //   return () => window.removeEventListener("scroll", onScroll);
    //   console.log(document.documentElement.offsetHeight, "hilih");
    // }
  }, [offset]);
  const getData = () => {
    fetch(`${server}/api/v1/fixedincome/list${sortBy}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        console.log(data, "okegas");
        setData(data.data.filter((item) => item.active == 1));
        // console.log(data, "promox");
      })

      .catch((err) => {
        // console.log(err);
      });
  };
  const getNewSwData = () => {
    fetch(`${server}/api/v2/swari/termSheet/INTERNAL`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        let temp = data.data.filter((item) => item.active == 1);
        console.log(temp, "sw_active");
        temp.push([]);

        setNewSwData(temp);
        // console.log(data.data, "kui");
      })

      .catch((err) => {
        // console.log(err);
      });
  };
  const getExpiredSwData = () => {
    fetch(`${server}/api/v2/swari/termSheet/EXPIRED`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        let temp = data.data.filter((item) => item.active == 1);
        console.log(temp, "sw_active");
        temp.push([]);

        setExpiredSwData(temp);
        console.log(data.data, "kui");
      })

      .catch((err) => {
        // console.log(err);
      });
  };

  const handleSort = (sortby) => {
    setSortBy(sortby);
    getData();
  };
  const dummy = [
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Korporasi",
      Kode: "FMFN2B",
      ama: "Obligasi I KB FInansia Multifinance Tahun",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "3.38%",
      coupon_payment: "Setiap 3 Bulan",
      expiry: "2026-09-15",
      buy: "101.60%",
      yield: "6.7657%",
      sell: "103.66%",
      yield_sell: "5.8874%",
    },
    {
      kategori: "Negara",
      Kode: "FR0056",
      Nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      Kode: "FR0056",
      Nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      Kode: "FR0056",
      Nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
  ];
  // let filteredData = [];
  // if (data !== null) {
  //   filteredData = data;
  // }
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const [imageLoaded, setImageLoaded] = useState(false);

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US";
    }

    return "https://apps.apple.com/id/app/kisi-mobile/id1493151464";
  };
  return (
    <div className={`${imageLoaded ? "o1" : "o0"}`}>
      <div class="imageDivWrapper  w70 px-5 ">
        {" "}
        <img
          class="w100 pointer"
          onLoad={() => {
            setImageLoaded(true);
          }}
          src={bondsBanner}
          onClick={(e) => navigate("/kisimobile")}
        ></img>
        {/* <img
          onLoad={() => {
            setImageLoaded(true);
          }}
          data-aos="fade-up"
          data-aos-duration="2000"
          src={margin}
          alt="banner image"
          class={`imageZ1 marginImg`}
        />
        <div class="insideWrapperAbsolute centerAbsolute768 sahamImgDiv marginDownloadDiv">
          <div class="downloadButtonGroup">
            <div class=" etfDownloadButtonContainer">
              <button
                class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
                onClick={() => navigate("/kisimobile")}
              >
                DOWNLOAD SEKARANG
              </button>

              <div class="flex mobileMarketplaceContainer">
                <div class="  flexStart playStore">
                  <img
                    class="mobileMarketplace"
                    src={playStore}
                    onClick={() =>
                      openInNewTab(
                        "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                      )
                    }
                  />
                </div>
                <div class="  flexEnd appStore">
                  <img
                    class="mobileMarketplace"
                    src={appStore}
                    onClick={() =>
                      openInNewTab(
                        "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="insideWrapperAbsolute sahamBannerText marginBannerText">
          <div class="sahamBannerTitle monsterratBlack marginBannerTitle">
            <span style={{ color: "#fbb03c" }}>
              PROMO MARGIN <br/></br> ACCOUNT
            </span>
            <br/></br>
            <div>
              <span class="f9r">12%</span>
              <span class="f15r">/tahun</span>
            </div>
            <div class="f1r marginPersenImg">
              <span>
                <img class="marginCheck" src={marginCheck}></img> Fee Beli 0.13%
                dan Jual 0.23%
              </span>{" "}
              <span>
                <img class="marginCheck" src={marginCheck}></img> 100+ pilihan
                saham
              </span>
              <div>
                {" "}
                <span>
                  <img class="marginCheck" src={marginCheck}></img> Periode
                  promosi Januari s/d Desember 2023
                </span>
              </div>
            </div>

            <p class="sahamBannerSubtitle"></p>
          </div>
        </div> */}
      </div>
      <div class="reksadanaUpperContainer mb-5">
        <div class="monsterratExtraBold  f275r mb-3 blue-text-1">
          Daftar Bonds
        </div>
        {/* <div class="monsterratMedium mt-2 f15r blue-text-1">
          Fund by KISI Mobile
        </div> */}
        <div class="monsterratMedium reksadanaDescriptionTxt grey jenisAkunText mt-3 f1r w80">
          Pilihan Bonds untuk solusi investasi anda
        </div>
        {/* <div class="monsterratMedium grey jenisAkunText mt-2 f1r">
          Untuk dapat melakukan transaksi Reksa Dana silahkan download Aplikasi
          KISI Mobile
        </div> */}
      </div>
      {/* <FollowSocmed /> */}
      <div
        class="w90 mx-auto mb-5 pb-5  "
        id="tabel_data_waran_wrapper"
        style={{ overflow: "auto" }}
      >
        <table
          id="tabel_data_waran_1"
          className="table  table-striped table-warrant"
          style={{ textAlign: "center", borderLeft: "0px solid transparent" }}
        >
          {/* <thead
            id="th_waran_top"
            style={{
              border: "none !important  ",
              position: "fixed",
              display: "none",
              // width: "90%",
              zIndex: 100,
              overflow: "auto",
              cursor: "context-menu",
            }}
          >
            <tr
              className="h1"
              style={{
                borderTop: "1px solid transparent",
              }}
            >
              <th
                className="tableWarrantHeaderFont monsterratBold py-4 blue-text-1"
                colSpan="13"
                style={{
                  borderStyle: "none",
                  backgroundColor: "#eef9ff",
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                }}
              >
                Data Waran Terstruktur
              </th>
              <th
                className="tableWarrantHeaderFont monsterratBold py-4 white px-4"
                colSpan="2"
                style={{
                  backgroundColor: "#0067b1",
                  borderBottom: "1px solid white",
                  whiteSpace: "nowrap",
                  borderTopRightRadius: 20,
                }}
              >
                Market Data
              </th>
            </tr>

            <tr
              className="h2 tableWarranFontContent"
              style={{
                textAlign: "left",
                borderLeft: "0px solid transparent",
                borderRight: "0px solid transparent",
              }}
            >
              <th
                className="  monsterratBold  justify-content-around blue-text-1 fixedTableCol"
                style={{
                  backgroundColor: "#eef9ff",
                  borderLeft: "0px solid transparent",
                  borderRight: "1px solid red !important",
                  verticalAlign: "middle",
                }}
              >
                <div
                  class="flex center-vertical  justify-content-between  justify-content-centerjustify-content-around"
                  style={{ verticalAlign: "middle" }}
                >
                  <span>Kategori</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/warrant_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/warrant_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1  noLeftBorder"
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between  justify-content-center">
                  <span>Kode</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/issuer_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/issuer_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between  justify-content-center h100 w100">
                  <span>Nama</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/base_stock_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/base_stock_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Rating</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/PutOrCall/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/PutOrCall/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Jenis Kupon</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/BaseStock_LastPrice/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/BaseStock_LastPrice/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kupon (% p.a)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/exercise_price/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/exercise_price/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Pembayaran Kupon</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/exercise_ratio/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/exercise_ratio/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Jatuh Tempo</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/expiry_date/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/expiry_date/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kami Beli</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/lastTradingDate/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/lastTradingDate/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Yield</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/maturity_days/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/maturity_days/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kami Jual</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/warrant_sensitivity_ticks/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/warrant_sensitivity_ticks/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Yield</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/effective_gearing/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/effective_gearing/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
            </tr>
          </thead> */}

          <thead
            id="th_waran_static"
            style={{
              border: "none !important  ",
            }}
          >
            {/* <tr
              className="h1"
              style={{
                borderTop: "1px solid transparent",
              }}
            >
              <th
                className="tableWarrantHeaderFont monsterratBold py-4 blue-text-1"
                colSpan="13"
                style={{
                  borderStyle: "none",
                  backgroundColor: "#eef9ff",
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                }}
              >
                Data Waran Terstruktur
              </th>
 
            </tr> */}

            <tr
              className="h2 tableWarranFontContent"
              style={{
                textAlign: "left",
                borderLeft: "0px solid transparent",
                borderRight: "0px solid transparent",
              }}
            >
              <th
                className="  monsterratBold  justify-content-around blue-text-1 fixedTableCol"
                style={{
                  backgroundColor: "#eef9ff",
                  borderLeft: "0px solid transparent",
                  borderRight: "1px solid red !important",
                  verticalAlign: "middle",
                }}
              >
                <div
                  class="flex center-vertical  justify-content-between  justify-content-center"
                  style={{ verticalAlign: "middle" }}
                >
                  <span>Kategori</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/bond_category/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/bond_category/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1  noLeftBorder"
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between  justify-content-center">
                  <span>Kode</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/bond_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/bond_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between  justify-content-center h100 w100">
                  <span>Nama</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/bond_name/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/bond_name/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Rating</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/rating/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/rating/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Jenis Kupon</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_type/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_type/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kupon (% p.a)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_rate/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_rate/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Pembayaran Kupon</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_payment/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_payment/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Jatuh Tempo</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/maturity_date/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/maturity_date/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kami Beli</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/buy_percent/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/buy_percent/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Yield</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/buy_yield/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/buy_yield/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kami Jual</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/sell_percent/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/sell_percent/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Yield</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/sell_yield/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/sell_yield/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
            </tr>
          </thead>
          <tbody id="formData tableWarrantFontContent">
            {data.map((data, i) => {
              return (
                <tr className="form_c" id={`data_waran_${i}`}>
                  <td
                    className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder fixedTableCol"
                    style={{ borderLeft: "1px solid transparent !important" }}
                  >
                    <a>{data.bond_category}</a>
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold tdRhb noLeftBorder">
                    {" "}
                    {data.bond_code}
                  </td>
                  <td
                    className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb"
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {data.bond_name}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.rating}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.coupon_type}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.coupon_rate}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.coupon_payment}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb nowrap">
                    {data.maturity_date ? (
                      moment(data.maturity_date).format("DD-MMM-YYYY")
                    ) : (
                      <div style={{ color: "transparent" }}>I</div>
                    )}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.buy_percent}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.buy_yield}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.sell_percent}{" "}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.sell_yield}
                  </td>

                  {/* Repeat the same structure for other table data cells */}
                </tr>
              );
            })}
            {[[]].map((data, i) => {
              return (
                <tr className="form_c" id="data_waran_end">
                  <td
                    className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol"
                    style={{ borderLeft: "1px solid transparent !important" }}
                  >
                    <a
                      onClick={() =>
                        this.getRicToDetails("KISIDRCJ4A", "KISIDRCJ4A")
                      }
                    >
                      {data.categpry}
                    </a>
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold tdRhb noLeftBorder">
                    {" "}
                    {data.issuer_code}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.base_stock_code}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.PutOrCall}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.BaseStock_LastPrice}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.exercise_price}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.exercise_ratio}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb nowrap">
                    {data.expiry_date ? (
                      moment(data.expiry_date).format("DD-MMM-YYYY")
                    ) : (
                      <div style={{ color: "transparent" }}>I</div>
                    )}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.maturity_days}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.warrant_sensitivity_ticks}{" "}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.effective_gearing}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div class="reksadanaUpperContainer mb-5 pb-5" style={{}}>
        <div
          class="monsterratBold  f25r mb-3 blue-text-1"
          style={{ fontSize: 36 }}
        >
          Apakah Obligasi ( Bond ) itu ?
        </div>

        <div class="monsterratMedium  mt-3 mb-5 f1r ">
          Obligasi adalah surat utang yang dapat dipindahtangankan, yang berisi
          janji dari pihak yang menerbitkan untuk membayar imbalan berupa bunga
          pada periode tertentu dan melunasi pokok utang pada waktu yang telah
          ditentukan kepada pihak pembeli obligasi tersebut.
        </div>

        <div
          class="monsterratBold pt-5 f25r mb-3 blue-text-1"
          style={{ fontSize: 36 }}
        >
          Beberapa istilah terkait Obligasi
        </div>

        <div class="monsterratSemiBold  mt-3 f1r mb-2 blue-text-1">
          Nilai Nominal (Face Value)
        </div>
        <div class="monsterratMedium  mt-2 f1r ">
          Nilai pokok dari suatu obligasi yang akan diterima oleh pemegang
          obligasi pada saat obligasi tersebut jatuh tempo.
        </div>
        <div class="monsterratSemiBold  mt-4 f1r mb-2 blue-text-1">
          Kupon (Interest Rate)
        </div>
        <div class="monsterratMedium  mt-2 f1r ">
          Nilai bunga yang diterima pemegang obligasi secara berkala (biasanya
          setiap 3 atau 6 bulanan).
        </div>
        <div class="monsterratSemiBold  mt-4 f1r mb-2 blue-text-1">
          Jatuh Tempo (Maturity)
        </div>
        <div class="monsterratMedium  mt-2 f1r ">
          Tanggal saat  pemegang obligasi akan mendapatkan pembayaran kembali
          pokok atau Nilai Nominal obligasi yang dimilikinya. Adapun periode
          jatuh tempo obligasi bervariasi mulai dari 365 hari sampai dengan
          diatas 20 tahun.
        </div>
        <div class="monsterratSemiBold  mt-4 f1r mb-2 blue-text-1">
          Imbal hasil (Yield)
        </div>
        <div class="monsterratMedium  mt-2 f1r ">
          Pengembalian yang diperoleh investor atas modal yang diinvestasikan
          untuk membeli obligasi.
        </div>
        <div class="monsterratSemiBold  mt-4 f1r mb-2 blue-text-1">
          Bunga Berjalan (Accrued Interest)
        </div>
        <div class="monsterratMedium  mt-2 f1r ">
          Total dari kupon harian yang akan diterima oleh investor selama
          memegang kepemilikan pada obligasi.
        </div>
        <div class="monsterratSemiBold  mt-4 f1r mb-2 blue-text-1">
          Penerbit (Issuer)
        </div>
        <div class="monsterratMedium  mt-2 f1r ">
          pihak yang menerbitkan obligasi, yaitu  pihak perusahaan atau
          pemerintah yang berutang pada investor sebagai pemegang obligasi yang
          diterbitkannya. 
        </div>
      </div>
      <div class="w100  bg-biru-muda  ">
        <div
          class="mt-5 rounded-corner w85 w951200 mx-auto"
          style={{ padding: "4rem 0rem 4rem 0rem" }}
        >
          <div
            class={`monsterratBold  f2r mb-5 blue-text-1  mw90768 mx-auto text-center`}
          >
            Keuntungan berinvestasi pada obligasi
          </div>
          <div
            class="w100 block768"
            style={{ display: "grid", gridTemplateColumns: "50% 50%" }}
          >
            {" "}
            <div class="w100">
              {" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid #0071BF",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid #0071BF",
                    marginBottom: "0.75rem",
                  }}
                >
                  Obligasi dapat dijual sebelum jatuh tempo
                </div>
                <div
                  class=" monsterratRegular blue-text-1  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Karena diperdagangkan di pasar sekunder maka obligasi dapat
                  dijual sebelum jatuh tempo. Khususnya Obligasi Negara FR yang
                  sangat likuid oleh karena itu dapat dijual kapan saja tanpa
                  harus tunggu jatuh tempo.
                </div>
              </div>{" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid #0071BF",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid #0071BF",
                    marginBottom: "0.75rem",
                  }}
                >
                  Memberikan pendapatan tetap (fixed income) berupa kupon atau
                  bunga.
                </div>
                <div
                  class=" monsterratRegular blue-text-1  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Kupon atau bunga dibayarkan secara regular sampai dengan jatuh
                  tempo dan ditetapkan dalam persentase dari nilai nominal dan
                  merupakan passive income bagi pemegang obligasi. Perhitungan
                  kupon dilakukan secara akumulasi setiap hari dari saat
                  obligasi dibeli hingga jatuh tempo. Jika obligasi dijual
                  sebelum jatuh tempo, investor sebagai penjual tetap mendapat
                  pembayaran kupon sesuai dengan lamanya waktu obligasi
                  dimiliki. Tingkat bunga atau kupon obligasi biasanya lebih
                  tinggi dari tingkat bunga yang ditawarkan deposito perbankan.
                </div>
              </div>{" "}
            </div>
            <div class="w100">
              {" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid #0071BF",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid #0071BF",
                    marginBottom: "0.75rem",
                  }}
                >
                  Imbal hasil (yield) yang sudah tetap
                </div>
                <div
                  class=" monsterratRegular blue-text-1  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Investor bisa mengunci imbal hasil (yield) yang akan diterima
                  sejak obligasi dibeli hingga jatuh tempo (yield-to-maturity).
                  Imbal hasil obligasi biasanya di atas suku bunga deposito dan
                  tidak dipengaruhi oleh naik turunnya perekonomian.
                </div>
              </div>{" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid #0071BF",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid #0071BF",
                    marginBottom: "0.75rem",
                  }}
                >
                  Potensi Keuntungan atas penjualan obligasi (capital gain)
                </div>
                <div
                  class=" monsterratRegular blue-text-1  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Selain penghasilan berupa kupon, pemegang obligasi dapat
                  memperoleh keuntungan ( capital gain) dengan menjual
                  obligasinya dengan harga lebih tinggi dibandingkan dengan
                  harga belinya.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w100    ">
        <div
          class="mt-5 rounded-corner w85 w951200 mx-auto"
          style={{ padding: "2.8rem 0rem 4rem 0rem" }}
        >
          <div
            class={`monsterratBold  f2r mb-5 blue-text-1  mw90768 mx-auto text-center`}
          >
            Risiko-risiko dalam berinvestasi pada obligasi
          </div>
          <div
            class="w100 block768"
            style={{ display: "grid", gridTemplateColumns: "50% 50%" }}
          >
            {" "}
            <div class="w100">
              {" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid black",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid black",
                    marginBottom: "0.75rem",
                  }}
                >
                  Risiko Tingkat Suku Bunga (Interest Rate Risk)
                </div>
                <div
                  class=" monsterratRegular  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Pergerakan harga obligasi sangat ditentukan pergerakan tingkat
                  suku bunga. Pergerakan harga obligasi berbanding terbalik
                  dengan tingkat suku bunga. Jika suku bunga naik maka harga
                  obligasi akan turun, sebaliknya jika suku bunga turun maka
                  harga obligasi akan naik.
                </div>
              </div>{" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid black",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid black",
                    marginBottom: "0.75rem",
                  }}
                >
                  Risiko gagal bayar( Default Risk)
                </div>
                <div
                  class=" monsterratRegular  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Adalah risiko penerbit tidak dapat membayar kewaiiban
                  (default) dalam hal ini tidak dapat membayar kupon obligasi
                  dan atau tidak dapat mengembalikan pokok obligasi. Jenis
                  risiko ini lebih dijumpai pada obligasi korporasi, walaupun
                  jarang terjadi. Sementara itu obligasi pemerintah (negara)
                  pembayaran kupon dan pokoknya 100% dijamin oleh negara dengan
                  Undang Undang.
                </div>
              </div>{" "}
            </div>
            <div class="w100">
              {" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid black",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid black",
                    marginBottom: "0.75rem",
                  }}
                >
                  Risiko Pasar (Market risk)
                </div>
                <div
                  class=" monsterratRegular  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Risiko ini terkait pasar yang mirip dengan tingkat risiko suku
                  bunga, dengan perbedaan risiko pasar berkaitan dengan kerugian
                  atau capital loss karena faktor-faktor seperti perubahan
                  tingkat suku bunga, situasi politik yang bergejolak, serta
                  perubahan ekonomi negara Investor berisiko mengalami capital
                  loss dari obligasi ketika menjual kembali produk tersebut di
                  harga yang lebih rendah pada pasar sekunder dibanding harga
                  belinya. Obligasi dengan jatuh tempo lebih panjang misalnya 10
                  tahun akan memiliki risiko pasar yang lebih besar dibandingkan
                  dengan yang jatuh tempo lebih pendek, misalnya dalam 2 tahun.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w100  bg-biru-muda  ">
        <div
          class="mt-5 rounded-corner w85 w951200 mx-auto"
          style={{ padding: "4rem 0rem 4rem 0rem" }}
        >
          <div
            class={`monsterratBold  f2r mb-5 blue-text-1  mw90768 mx-auto text-center`}
          >
            Apakah Sukuk?
          </div>
          <div
            class="w100 block768"
         
          >
            <div class="w100">
              {" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid #0071BF",
                  height: "fit-content",
                }}
              >
                <div
                  class=" monsterratRegular blue-text-1  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  <p>
                    Sukuk atau dikenal sebagai obligasi syariah adalah surat
                    berharga yang merupakan bukti kepemilikan suatu aset pada
                    jangka waktu tertentu, berdasarkan prinsip syariah yang
                    dikeluarkan emiten, yang mewajibkan emiten untuk membayar
                    pendapatan kepada pemegang sukuk berupa bagi hasil serta
                    membayar kembali dana sukuk pada saat jatuh tempo.
                  </p>

                  <h4 class="monsterratSemiBold">
                    Perbedaan sukuk dan obligasi adalah :
                  </h4>
                  <ul>
                    <li>
                      Obligasi adalah surat hutang, sedangkan sukuk adalah bukti
                      kepemilikan atas suatu aset.
                    </li>
                    <li>
                      Obligasi membayar pendapatan dalam bentuk bunga, sedangkan
                      sukuk membayar pendapatan dalam bentuk bagi hasil.
                    </li>
                  </ul>

                  <h4 class="monsterratSemiBold">Jenis-jenis Sukuk</h4>
                  <h6 class="monsterratSemiBold">
                    Berdasarkan Pihak yang Menerbitkan, pembagian jenis sukuk
                    adalah sukuk negara dan sukuk korporasi.
                  </h6>
                  <ol>
                    <li>
                      <span class="monsterratSemiBold">
                        Surat Berharga Syariah Negara (SBSN)
                      </span>
                      <br />
                      Surat Berharga Syariah Negara atau SBSN disebut juga sukuk
                      negara, yaitu surat berharga yang dikeluarkan oleh negara
                      sebagai bukti atas bagian kepemilikan aset negara.
                    </li>
                    <li>
                      <span class="monsterratSemiBold">Sukuk Korporasi</span>
                      <br />
                      Sukuk yang diterbitkan oleh pemilik obligasi syariah dalam
                      suatu perusahaan.
                    </li>
                  </ol>

                  <h6 class="monsterratSemiBold">
                    Berdasarkan akad syariah, ada 2 jenis sukuk yang umum
                    dikenal :
                  </h6>
                  <ol>
                    <li>
                      <span class="monsterratSemiBold">Sukuk Mudharabah</span>
                      <br />
                      adalah sukuk dengan perjanjian atau akad mudharabah. Dalam
                      hal ini ada pihak pemberi modal (rab al-maal) dan
                      pengelola modal (mudharib). Pembagian keuntungannya
                      tergantung dari perbandingan yang telah disepakati.
                      Sementara kerugian akan ditanggung sepenuhnya oleh pemilik
                      modal.
                    </li>
                    <li>
                      <span class="monsterratSemiBold">Sukuk Ijarah</span>
                      <br />
                      adalah sukuk dengan akad memindahkan hak penggunaan atas
                      barang dan jasa, tanpa ikut memindahkan kepemilikan. Akad
                      ijarah dalam sukuk adalah berupa sewa. Jadi hak
                      kepemilikan tetap pada orang yang sama.
                    </li>
                  </ol>

                  <h4 class="monsterratSemiBold">
                    Keuntungan dalam Berinvestasi pada Sukuk
                  </h4>
                  <ul>
                    <li>
                      Arus kas pendapatan periodik yang dapat diprediksi, berupa
                      imbalan/kupon dengan jumlah tetap seperti pada obligasi
                      konvensional, namun melalui pembagian profit halal sesuai
                      dengan prinsip syariah.
                    </li>
                    <li>
                      Memiliki risiko default yang lebih rendah dibanding
                      obligasi konvensional, karena sukuk didukung oleh aset
                      nyata, tidak hanya berdasarkan kredit penerbit.
                    </li>
                    <li>
                      Kepatuhan pada prinsip syariah dengan menghindari unsur
                      riba (bunga), spekulasi yang berlebihan, dan investasi
                      dalam bisnis yang haram.
                    </li>
                    <li>
                      Potensi memperoleh capital gain jika sukuk dijual di harga
                      yang lebih tinggi daripada harga beli dengan
                      memperhitungkan biaya transaksi.
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class={`monsterratBold  f2r mb-5 blue-text-1  mw90768 mx-auto text-center`}
              >
                Sekuritas Rupiah Bank Indonesia
              </div>
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid #0071BF",
                  height: "fit-content",
                }}
              >
                <div
                  class=" monsterratRegular blue-text-1  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  <p>
                    Sekuritas Rupiah Bank Indonesia (SRBI) adalah surat berharga
                    dalam mata uang rupiah yang diterbitkan oleh BI sebagai
                    pengakuan utang berjangka waktu pendek dengan menggunakan
                    underlying asset berupa surat berharga negara (SBN) yang
                    dimiliki oleh BI.
                  </p>

                  <h4 class="monsterratSemiBold">Karakteristik SRBI</h4>
                  <ul>
                    <li>Menggunakan underlying asset berupa SBN</li>
                    <li>Berjangka waktu 1 minggu sampai dengan 12 bulan</li>
                    <li>
                      Diterbitkan tanpa warkat dan diperdagangkan dengan sistem
                      diskonto
                    </li>
                    <li>Dapat dipindahtangankan</li>
                    <li>
                      Dapat dimiliki oleh penduduk dan bukan penduduk di pasar
                      sekunder
                    </li>
                  </ul>

                  <p>
                    Berbeda dengan obligasi dan sukuk, SRBI tidak memberikan
                    pendapatan periodik hingga jatuh tempo. Investor membeli
                    SRBI dengan harga diskon dan menerima pengembalian dana
                    berupa pokok SRBI pada saat jatuh tempo.
                  </p>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
