import logo from "../../logo.svg";
import "./style.css";
import PageLocation from "../../components/PageLocation";
import waran1 from "../../assets/waran1.png";
import waran2 from "../../assets/waran2.png";
import waran3 from "../../assets/waran3.png";
import waran4 from "../../assets/waran4.png";
import syariahSertifikatMui from "../../assets/syariahSertifikatMui.pdf";
import manual_order_e_ipo from "../../assets/manual_order_e_ipo.pdf";
import manualPendaftaranEIpo from "../../assets/manualPendaftaranEIpo.pdf";
import koins from "../../assets/koins.png";
import panduanImg from "../../assets/panduanImg.png";
import kisiMobile2 from "../../assets/kisiMobile2.png";
import letter from "../../assets/letter.png";
import Swal from "sweetalert2";
import close from "../../assets/close.png";
import withReactContent from "sweetalert2-react-content";
import mail from "../../assets/email.png";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { storageLocation } from "../../config/server";
import { setActiveMenu } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../config/server";
import { host, get_manual } from "../../config/server";
import YouTubePlaylist from "@codesweetly/react-youtube-playlist";
import playStore from "../../assets/icon-download-android.svg";
import appStore from "../../assets/icon-download-ios.svg";
// import playStore from "../../assets/playStore.png";
// import appStore from "../../assets/appStore.png";
export function Panduan() {
  const { page } = useParams();
  const dispatch = useDispatch();
  const ytApikey = process.env.REACT_APP_YT_API_KEY;
  const [panduanList, setPanduanList] = useState([]);
  const MySwal = withReactContent(Swal);
  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(page.substring(1, page.length), page);
    // document.getElementById(page).className +=
    //   " subPathActive";
    getPanduan();
    // console.log(page);
    dispatch(setActiveMenu("Produk"));
    let subpath = document.getElementsByClassName("panduanSubPath");
    // console.log("asu", subpath);
    for (let i = 0; i < subpath.length; i++) {
      const element = subpath[i];
      // console.log(element.id);
      if (element.id == page) {
        document.getElementById(page).className += " subPathActive";
      } else {
        element.className =
          "subPath monsterratMedium panduanSubPath rounded-pill mx-3 px-3";
      }
    }

    // switch (page) {
    //   case "pembukaan_akun":
    //     document.getElementById("pembukaan_akun").className += " subPathActive";
    //     document.getElementById("koins").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     document.getElementById("e_ipo").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     document.getElementById("kinds").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     break;

    //   case "koins":
    //     // console.log("swituseG");
    //     document.getElementById("koins").className += " subPathActive";
    //     document.getElementById("pembukaan_akun").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     document.getElementById("e_ipo").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     document.getElementById("kinds").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     break;
    //   case "kinds":
    //     // console.log("swituseG");
    //     document.getElementById("kinds").className += " subPathActive";
    //     document.getElementById("pembukaan_akun").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     document.getElementById("e_ipo").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     document.getElementById("koins").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     break;
    //   case "e_ipo":
    //     // console.log("swituseG");
    //     document.getElementById("e_ipo").className += " subPathActive";
    //     document.getElementById("pembukaan_akun").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     document.getElementById("koins").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     document.getElementById("kinds").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     break;
    //   case "kisi_mobile":
    //     // console.log("swituseG");
    //     document.getElementById("e_ipo").className += " subPathActive";
    //     document.getElementById("pembukaan_akun").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     document.getElementById("koins").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     document.getElementById("kinds").className =
    //       "subPath monsterratMedium panduanSubPath rounded-pill mx-3";
    //     break;
    //   default:
    //     break;
    // }
  }, [page]);
  const navigate = useNavigate();
  // const getPanduan = (file) => {
  //   // const host = `https://www.kisi.co.id/storage/panduan/`

  //   var win = window.open(host + `/storage/panduan/` + file, "_blank");
  //   win.focus();
  // };
  const getPanduan = (file) => {
    fetch(server + `/api/v1/guidance/list`)
      .then((res) => res.json())
      .then((panduan) => {
        console.log(panduan);
        setPanduanList(panduan.data);
        // openInNewTab(panduan)

        // resolve("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const PanduanModal = (e, panduan) => {
    e.preventDefault();
    // console.log(panduan);
    const eventModal = MySwal.mixin({
      // toast: true,
      class:'mw80 mw901000',
      allowOutsideClick: false,
      customClass: "mw80",
      className: "mw80",
      showClass: {
        // backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster  center-vertical swalPanduan",
        icon: "animated heartBeat delay-1s",
      },
      hideClass: {
        popup: "animated fadeOutUp faster",
      },
      showConfirmButton: false,
      showLoaderOnConfirm: true,

      backdrop: true,

      html: (
        <div class="justify-content-center px-2  text-center">
          {/* {JSON.stringify(panduan)} */}
          <div class="text-right" style={{}}>
            <img
              onClick={(e) => {
                MySwal.close();
              }}
              style={{
                height: 30,
                marginLeft: "auto",
                marginBottom: "1.33rem",
                marginRight: "-5px",
              }}
              src={close}
              class="pointer"
            ></img>
          </div>
          <div
            class="flex  "
            style={{
       
              overflowX: "hidden",
              maxWidth: "100%",
              flexWrap: "wrap",
              justifyContent:"center"
            }}
          >
            {[[], [], [], [], [], [], [],[],[],[]].map((p, i) => {
              return (
                <>
                  {panduan[`file${String(i)}_subfile`] ? (
                    <div
                      class="panduanDetailContainer mx-3 panduanPopup py-3 my-2 text-center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div class="  white monsterratRegular f13r text-center w100 px-3 mb-3 pb-2">
                        {panduan[`file${String(i)}_title`]}
                      </div>

                      <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                        <button
                          class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold"
                          style={{ borderRadius: 12 }}
                          onClick={(e) =>
                            openInNewTab(
                              `${get_manual}${panduan.guidance_detail_id}/${
                                panduan[`file${String(i)}_slug`]
                              }`
                            )
                          }
                        >
                          Download
                          {/* {i}{`${get_manual}${panduan.guidance_detail_id}/${panduan[`file${String(i)}_slug`] }`} */}
                        </button>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
            {/* <div
              class="panduanDetailContainer mx-3 panduanPopup py-3 my-2 text-center"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div class="  white monsterratRegular f13r text-center w100 px-3 mb-3 pb-2">
                PM PEMROSESAN DATA PRIBADI
              </div>

              <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                <button
                  class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold"
                  style={{ borderRadius: 12 }}
                  onClick={(e) =>
                    openInNewTab(
                      `${server}${storageLocation}${
                        panduan.guidance_category_id == 2
                          ? "panduan/PM PENGISIAN FORM PDP INDIVIDU (EXT).pdf"
                          : "panduan/PM PENGISIAN FORM PDP INSTITUSI (EXT).pdf"
                      }`
                    )
                  }
                >
                  Download
           
                </button>
              </div>
            </div> */}
          </div>

          {/* <div class=" eventModalImgDiv  py-1 px-3">
            <div
              class="none-desktop d1000"
              style={{ float: "right", textAlign: "right" }}
            >
              <img
                onClick={(e) => {
                  MySwal.close();
                }}
                style={{ height: 40, marginLeft: "auto" }}
                src={close}
              ></img>
            </div>
            <img
              class="loadingImgModal eventModalImg "
              // src={`${server}${storageLocation}${e.image_event_link}`}
            />
          </div>
          <div class="eventPopupDetail w100 " style={{ paddingLeft: 30 }}>
            <div class="none-desktop d1000" style={{ height: 40 }}></div>
            <div
              class="none-1000"
              style={{ float: "right", textAlign: "right" }}
            >
              <img
                onClick={(e) => {
                  MySwal.close();
                }}
                style={{ height: 40, marginLeft: "auto" }}
                src={close}
              ></img>
            </div>
            <div style={{ textAlign: "left" }}>
              <div class="f2r blue-text-1 monsterratBold mb-3">
                {" "}
                {e.title_event}
              </div>
              <div class="monsterratMedium f1r grey mb-3 ">
                {" "}
                {e.content_event}
              </div>
              <div class="monsterratMedium f12r mb-3 ">
                {" "}
                {e.branch_company.branch_name}
              </div>
              <div class="monsterratMedium grey mb-3 f1r">
               
              </div>

              <div class="flex">
                <div style={{ marginRight: "1.2rem" }}>
              
                </div>
                <div class="monsterratMedium  f1r center-vertical">
                  {e.place}
                </div>
              </div>
            </div>
            <div style={{ textAlign: "right" }}>
              <button
                style={{
                  borderRadius: 12,
                  paddingTop: "0.66rem",
                  paddingBottom: "0.66rem",
                }}
                class="text-center monsterratSemiBold blue-text-1 f085r cabangTabButton px-4 "
                onClick={(x) => {
                  // e.preventDefault()
                  navigate(`/cabang/${e.branch_id}`);
                  MySwal.close();
                }}
              >
                Informasi <i>Sales</i>
              </button>
            </div>
          </div> */}
        </div>
      ),
      showConfirmButton: false,
      heightAuto: false,
    });
    // console.log("gagag");
    eventModal.fire();
  };
  const PanduanModal2 = (e, panduan) => {
    e.preventDefault();
    // console.log(panduan);
    const eventModal = MySwal.mixin({
      // toast: true,
      allowOutsideClick: false,
      customClass: "",
      className: "",
      showClass: {
        // backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "animated fadeInDown faster  center-vertical swalPanduan",
        icon: "animated heartBeat delay-1s",
      },
      hideClass: {
        popup: "animated fadeOutUp faster",
      },
      showConfirmButton: false,
      showLoaderOnConfirm: true,

      backdrop: true,

      html: (
        <div class="justify-content-center px-2  text-center">
          {/* {JSON.stringify(panduan)} */}
          <div class="text-right" style={{}}>
            <img
              onClick={(e) => {
                MySwal.close();
              }}
              style={{
                height: 30,
                marginLeft: "auto",
                marginBottom: "1.33rem",
                marginRight: "-5px",
              }}
              src={close}
              class="pointer"
            ></img>
          </div>
          {/* {JSON.stringify(panduan)} */}
          <div
            class="flex  center-vertical"
            style={{
              display: "-webkit-inline-box",
              overflowX: "hidden",
              maxWidth: "100%",
              flexWrap: "wrap",
            }}
          >
            {panduan.map((p, i) => {
              return (
                <>
                  <div
                    class="panduanDetailContainer mx-3 panduanPopup py-3 my-2 text-center"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div class="  white monsterratRegular f13r text-center w100 px-3 mb-3 pb-2">
                      {p.guidance_detail_title}
                    </div>

                    <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                      <button
                        class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold"
                        style={{ borderRadius: 12 }}
                        onClick={(e) =>
                          openInNewTab(
                            `${get_manual}${p.guidance_detail_id}/${
                              p[`file1_slug`]
                            }`
                          )
                        }
                      >
                        Download
                        {/* {i}{`${get_manual}${panduan.guidance_detail_id}/${panduan[`file${String(i)}_slug`] }`} */}
                      </button>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          {/* <div class=" eventModalImgDiv  py-1 px-3">
            <div
              class="none-desktop d1000"
              style={{ float: "right", textAlign: "right" }}
            >
              <img
                onClick={(e) => {
                  MySwal.close();
                }}
                style={{ height: 40, marginLeft: "auto" }}
                src={close}
              ></img>
            </div>
            <img
              class="loadingImgModal eventModalImg "
              // src={`${server}${storageLocation}${e.image_event_link}`}
            />
          </div>
          <div class="eventPopupDetail w100 " style={{ paddingLeft: 30 }}>
            <div class="none-desktop d1000" style={{ height: 40 }}></div>
            <div
              class="none-1000"
              style={{ float: "right", textAlign: "right" }}
            >
              <img
                onClick={(e) => {
                  MySwal.close();
                }}
                style={{ height: 40, marginLeft: "auto" }}
                src={close}
              ></img>
            </div>
            <div style={{ textAlign: "left" }}>
              <div class="f2r blue-text-1 monsterratBold mb-3">
                {" "}
                {e.title_event}
              </div>
              <div class="monsterratMedium f1r grey mb-3 ">
                {" "}
                {e.content_event}
              </div>
              <div class="monsterratMedium f12r mb-3 ">
                {" "}
                {e.branch_company.branch_name}
              </div>
              <div class="monsterratMedium grey mb-3 f1r">
               
              </div>

              <div class="flex">
                <div style={{ marginRight: "1.2rem" }}>
              
                </div>
                <div class="monsterratMedium  f1r center-vertical">
                  {e.place}
                </div>
              </div>
            </div>
            <div style={{ textAlign: "right" }}>
              <button
                style={{
                  borderRadius: 12,
                  paddingTop: "0.66rem",
                  paddingBottom: "0.66rem",
                }}
                class="text-center monsterratSemiBold blue-text-1 f085r cabangTabButton px-4 "
                onClick={(x) => {
                  // e.preventDefault()
                  navigate(`/cabang/${e.branch_id}`);
                  MySwal.close();
                }}
              >
                Informasi <i>Sales</i>
              </button>
            </div>
          </div> */}
        </div>
      ),
      showConfirmButton: false,
      heightAuto: false,
    });
    // console.log("gagag");
    eventModal.fire();
  };
  function detectKOINS() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"

    if (/android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=com.koins.mtsand&hl=in&gl=US";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "https://apps.apple.com/id/app/koins-mobile/id1436459041";
    }
    return "https://apps.apple.com/id/app/koins-mobile/id1436459041";
  }
  const PanduanOpeningAcc = () => {
    return (
      <div>
        <div class=" mx-auto panduanWrapper">
          {/* <div class="f25r blue-text-1 monsterratExtraBold center768  text-center pb-3 mb-5 none-mobile">
            Panduan Pembukaan Akun Secara <i>Online</i> dan <i>Offline</i>
          </div> */}

          <div class="f25r blue-text-1 monsterratExtraBold center768  mb-5 ">
            Pedoman Pembukaan Akun Secara <i>Online</i> (Pribadi)
          </div>
          <div class="flex">
            <div>
              <img
                class=""
                style={{
                  height: "6rem",
                }}
                src={kisiMobile2}
              />
            </div>
            <div
              class="mx-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div class="blue-text-1 f15r monsterratBold "> KISI Mobile</div>

              <div
                class="flex "
                style={{
                  paddingBottom: 5,
                }}
              >
                <div class="text-center">
                  <img
                    onClick={() =>
                      openInNewTab(
                        "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                      )
                    }
                    class="downloadImgPanduan "
                    style={{ height: "3rem", marginRight: "1.3rem" }}
                    src={playStore}
                  />
                </div>
                <div class="text-center ">
                  <img
                    onClick={() =>
                      openInNewTab(
                        "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                      )
                    }
                    class="downloadImgPanduan "
                    style={{ height: "3rem", marginRight: "1.3rem" }}
                    src={appStore}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="w100 flex py-5 panduanMain  "
            style={{
              // justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {panduanList
              .filter((panduan) => panduan.guidance_category_id == 1)
              .map((panduan, i) => {
                return (
                  <div class="panduanDetailContainer py-3 mb-5 text-center">
                    <span class="  white monsterratRegular f14r text-center w100 px-3">
                      {panduan.guidance_detail_title}
                    </span>
                    <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                      {panduan.guidance_detail_id == 3 ||
                      panduan.guidance_detail_id == 4 ? (
                        <div
                          class="manualSub py-3 f085r monsterratLight"
                          style={{}}
                        >
                          *Jika nasabah belum memiliki Akun Reguler
                        </div>
                      ) : null}
                      {panduan.guidance_detail_id == 2 ||
                      panduan.guidance_detail_id == 5 ? (
                        <div
                          class="manualSub py-3 f085r monsterratLight"
                          style={{}}
                        >
                          *Jika nasabah sudah memiliki Akun Reguler
                        </div>
                      ) : null}
                      <button
                        class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold"
                        style={{ borderRadius: 12 }}
                        onClick={(e) =>
                          openInNewTab(
                            `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
                          )
                        }
                      >
                        Download Buku Panduan
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>

          <div class="f25r blue-text-1 monsterratExtraBold center768 pb-5 mt-5 ">
            Pedoman Pembukaan Akun Secara <i>Offline</i> (Pribadi)
          </div>
          <div class="f165r blue-text-1 monsterratBold center768   ">
            Akun Reguler atau Akun Margin
          </div>

          <div
            class="w100 flex py-5 panduanMain  "
            style={{
              // justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {" "}
            {panduanList
              .filter((panduan) => panduan.guidance_category_id == 2)
              .map((panduan, i) => {
                return (
                  <div class="panduanDetailContainer py-3 mb-5 text-center">
                    {/* {JSON.stringify(panduan)} */}
                    <div class=" white monsterratRegular f14r text-center w100 px-4">
                      {panduan.guidance_detail_title}
                    </div>
                    <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                      <button
                        class="w100 f1r py-2  mt-3 blue-text-1 manualButton monsterratSemiBold"
                        style={{ borderRadius: 12 }}
                        onClick={(e) => PanduanModal(e, panduan)}
                      >
                        Download Buku Panduan
                      </button>
                      <button
                        class="w100 f1r py-2  mt-3 blue-text-1 manualButton monsterratSemiBold"
                        style={{ borderRadius: 12 }}
                        onClick={(e) =>
                          openInNewTab(
                            `${get_manual}${panduan.guidance_detail_id}/${panduan.file4_slug}`
                          )
                        }
                      >
                        Download Formulir
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>

          <div class="f25r blue-text-1 monsterratExtraBold center768 pb-5 mt-5 ">
            Pedoman Pembukaan Akun Secara <i>Offline</i> (Institusi)
          </div>
          <div class="f165r blue-text-1 monsterratBold center768   ">
            Akun Reguler atau Akun Margin
          </div>

          <div
            class="w100 flex py-5 panduanMain  "
            style={{
              // justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {" "}
            {panduanList
              .filter((panduan) => panduan.guidance_category_id == 3)
              .map((panduan, i) => {
                return (
                  <div class="panduanDetailContainer py-3 mb-5 text-center">
                    {/* {JSON.stringify(panduan)} */}
                    <div class=" white monsterratRegular f14r text-center w100 px-4">
                      {panduan.guidance_detail_title}
                    </div>
                    <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                      <button
                        class="w100 f1r py-2  mt-3 blue-text-1 manualButton monsterratSemiBold"
                        style={{ borderRadius: 12 }}
                        onClick={(e) => PanduanModal(e, panduan)}
                      >
                        Download Buku Panduan
                      </button>
                      <button
                        class="w100 f1r py-2  mt-3 blue-text-1 manualButton monsterratSemiBold"
                        style={{ borderRadius: 12 }}
                        onClick={(e) =>
                          openInNewTab(
                            `${get_manual}${panduan.guidance_detail_id}/${panduan.file4_slug}`
                          )
                        }
                      >
                        Download Formulir
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
          <div class="f25r blue-text-1 monsterratExtraBold center768  mt-5 ">
            Pedoman Pengkinian Data 
          </div>
  

          <div
            class="w100 flex py-5 panduanMain  "
            style={{
              // justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {" "}
            {panduanList
              .filter((panduan) => panduan.guidance_category_id == 9)
              .map((panduan, i) => {
                return (
                  <div class="panduanDetailContainer py-3 mb-5 text-center">
                    {/* {JSON.stringify(panduan)} */}
                    <div class=" white monsterratRegular f14r text-center w100 px-4">
                      {panduan.guidance_detail_title}
                    </div>
                    <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                      <button
                        class="w100 f1r py-2  mt-3 blue-text-1 manualButton monsterratSemiBold"
                        style={{ borderRadius: 12 }}
                        onClick={(e) => PanduanModal(e, panduan)}
                      >
                        Download
                      </button>
                      {/* <button
                        class="w100 f1r py-2  mt-3 blue-text-1 manualButton monsterratSemiBold"
                        style={{ borderRadius: 12 }}
                        onClick={(e) =>
                          openInNewTab(
                            `${get_manual}${panduan.guidance_detail_id}/${panduan.file4_slug}`
                          )
                        }
                      >
                        Download Formulir
                      </button> */}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="kisiTalkContainer mb-5 pb-5">
            <div className="kisiTalkWrapper">
              <div class="f25r blue-text-1 monsterratExtraBold center768  text-center pb-3 mb-5 ">
                Video Tutorial Aplikasi KISI Mobile
              </div>

              <div className="center-vertical iframeHome">
                <iframe
                  width="100%"
                  height={window.innerHeight > 768 ? "auto" : "500px"}
                  src="https://www.youtube.com/embed/W4QEWbHLiUQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  id="yt-koins"
                  className="iframe-koins iframe-main-playlist"
                ></iframe>

                <div className="ytOtherContainer">
                  <YouTubePlaylist
                    apiKey={ytApikey}
                    playlistId="PL0TzfdbrGZVHcyZ9AciGMisFnCApcXd4w"
                    uniqueName="youtube-playlist-container"
                    className="koinsPlaylist"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PanduanKoins = () => {
    return (
      <div class="mb-5 pb5">
        <div class=" mx-auto  panduanWrapper">
          <div class="f25r blue-text-1 monsterratExtraBold center768  text-center pb-3 mb-5 ">
            Pedoman Aplikasi KOINS HTS dan KOINS MTS
          </div>

          <div class="f165r blue-text-1 monsterratBold center768  text-center ">
            Menu dan Penjelasan All - In - One
          </div>
          <div
            class="w100 flex py-5 panduanMain  "
            style={{
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {panduanList
              .filter((panduan) => panduan.guidance_product_id == 2)
              .map((panduan, i) => {
                return (
                  <div class="panduanDetailContainer py-3 mb-5 text-center">
                    <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
                      {panduan.guidance_detail_title}
                    </span>
                    <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                      <button
                        class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
                        style={{ borderRadius: 12 }}
                        onClick={(e) =>
                          openInNewTab(
                            `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
                          )
                        }
                      >
                        Download Buku Panduan
                      </button>

                      {i == 1 ? (
                        <div
                          class="flex "
                          style={{
                            justifyContent: "space-between",
                          }}
                        >
                          <div class="text-center">
                            <img
                              onClick={() =>
                                openInNewTab(
                                  "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                                )
                              }
                              class="downloadImgPanduan "
                              style={{
                                height: "2.5rem",
                                marginRight: "1.3rem",
                              }}
                              src={playStore}
                            />
                          </div>
                          <div class="text-center">
                            <img
                              onClick={() =>
                                openInNewTab(
                                  "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                                )
                              }
                              class="downloadImgPanduan "
                              style={{ height: "2.5rem" }}
                              src={appStore}
                            />
                          </div>
                        </div>
                      ) : (
                        <button
                          class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold "
                          style={{ borderRadius: 12 }}
                          onClick={(e) =>
                            openInNewTab(
                              `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
                            )
                          }
                        >
                          Download Sistem
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          {/* <table class="table table-bordered textCenter monsterratMedium lh25 panduanTable mb-5 pb-5 none-mobile">
            <thead>
              <tr></tr>
            </thead>

            <colgroup>
              <col colspan="1" style={{ width: "35%" }}></col>

              <col colspan="1" style={{ width: "16.25%" }}></col>
              <col colspan="1" style={{ width: "16.25%" }}></col>
            </colgroup>

            <tbody>
              <tr class="manualTableWidth">
                <th
                  class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-5"
                  rowspan="2"
                >
                  Penggunaan KOINS
                </th>
                <th
                  class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-4"
                  colspan="2"
                >
                  KOINS HTS (Windows)
                </th>
                <th
                  class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-4"
                  colspan="2"
                >
                  KOINS MTS (Mobile)
                </th>
              </tr>
              <tr class="manualTableWidth">
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 ">
                  Buku Pedoman
                </th>
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 ">
                  Sistem
                </th>
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 ">
                  Buku Pedoman
                </th>
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 ">
                  Sistem
                </th>
              </tr>
              <tr class="manualTableWidth">
                <th
                  class="table-center h100 monsterratMedium py18r blue-text-1 f11r"
                  rowspan="1"
                >
                  Menu dan Penjelasan<br></br>
                  All - In - One
                </th>
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(`${get_manual}13/manual_koins_hts`)
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>{" "}
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(host + `/download/KOINS_HTS_setup.exe`)
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>{" "}
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(`${get_manual}13/manual_koins_mts`)
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>{" "}
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={() => openInNewTab(detectKOINS())}
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>
            </tbody>
          </table> */}
        </div>
        <div className="kisiTalkContainer mb-5 pb-5">
          <div className="kisiTalkWrapper">
            <div class="f25r blue-text-1 monsterratExtraBold center768  text-center pb-3 mb-5 ">
              Video Tutorial Aplikasi KOINS HTS dan KOINS MTS
            </div>

            <div className="iframeKoinsContainer  center-vertical iframeHome">
              <iframe
                width="100%"
                height={window.innerHeight > 768 ? "auto" : "500px"}
                src="https://www.youtube.com/embed/DY_pBXGdzv4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                id="yt-koins"
                className="iframe-koins iframe-main-playlist"
              ></iframe>

              <div className="ytOtherContainer">
                <YouTubePlaylist
                  apiKey={ytApikey}
                  playlistId="PL0TzfdbrGZVEckifL-y7Ryirs-It6PZ7y"
                  uniqueName="youtube-playlist-container"
                  className="koinsPlaylist"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  // const PanduanKoins = () => {
  //   let panduanMtsArr = panduanList
  //     .filter((panduan) => panduan.guidance_product_id == 7)
  //     .filter((panduan) => panduan.file1_slug);
  //   let panduanMTS = {};

  //   for (let i = 0; i < panduanMtsArr.length; i++) {
  //     const element = panduanMtsArr[i];
  //     if (panduanMTS[element.guidance_category.guidance_category_title]) {
  //       panduanMTS[element.guidance_category.guidance_category_title].push(
  //         element
  //       );
  //     } else {
  //       panduanMTS[element.guidance_category.guidance_category_title] = [
  //         element,
  //       ];
  //     }
  //   }
  //   let panduanHTSArr = panduanList
  //     .filter((panduan) => panduan.guidance_product_id == 8)
  //     .filter((panduan) => panduan.file1_slug);
  //   let panduanHTS = {};

  //   for (let i = 0; i < panduanHTSArr.length; i++) {
  //     const element = panduanHTSArr[i];
  //     if (panduanHTS[element.guidance_category.guidance_category_title]) {
  //       panduanHTS[element.guidance_category.guidance_category_title].push(
  //         element
  //       );
  //     } else {
  //       panduanHTS[element.guidance_category.guidance_category_title] = [
  //         element,
  //       ];
  //     }
  //   }
  //   console.log(Object.keys(panduanMTS));

  //   return (
  //     <div class="mb-5 pb5">
  //       <div class=" mx-auto  panduanWrapper">
  //         <div class="f25r blue-text-1 monsterratExtraBold center768  mb-5 ">
  //           Pedoman Aplikasi KOINS MTS
  //         </div>

  //         {/* <div class="f165r blue-text-1 monsterratBold center768  text-center ">
  //           Menu dan Penjelasan All - In - One
  //         </div> */}
  //         <div
  //           class="w100 flex py-2 panduanMain  "
  //           style={{
  //             // justifyContent: "center",
  //             flexWrap: "wrap",
  //           }}
  //         >
  //           {Object.keys(panduanMTS).map((keyName, i) => (
  //             <>
  //               {/* <div class="f165r blue-text-1 monsterratBold center768  text-center ">
  //                 {keyName}
  //               </div> */}
  //               {panduanMTS[keyName].length == 1 ? (
  //                 <>
  //                   {" "}
  //                   {panduanMTS[keyName].map((panduan, i) => {
  //                     return (
  //                       <div class="panduanDetailContainer py-3 mb-5 text-center">
  //                         <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
  //                           {panduan.guidance_detail_title}
  //                         </span>
  //                         <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
  //                           <button
  //                             class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
  //                             style={{ borderRadius: 12 }}
  //                             onClick={(e) =>
  //                               openInNewTab(
  //                                 `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
  //                               )
  //                             }
  //                           >
  //                             Download Buku Panduan
  //                           </button>
  //                         </div>
  //                       </div>
  //                     );
  //                   })}
  //                 </>
  //               ) : (
  //                 <>
  //                   <div class="panduanDetailContainer py-3 mb-5 text-center">
  //                     <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
  //                       {keyName}
  //                     </span>
  //                     <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
  //                       <button
  //                         class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
  //                         style={{ borderRadius: 12 }}
  //                         onClick={(e) => PanduanModal2(e, panduanMTS[keyName])}
  //                       >
  //                         Download Buku Panduan
  //                       </button>
  //                     </div>
  //                   </div>
  //                 </>
  //               )}

  //               {/* {panduanMTS[keyName].map((panduan, i) => {
  //                   return (
  //                     <div class="panduanDetailContainer py-3 mb-5 text-center">
  //                       <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
  //                         {panduan.guidance_detail_title}
  //                       </span>
  //                       <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
  //                         <button
  //                           class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
  //                           style={{ borderRadius: 12 }}
  //                           onClick={(e) =>
  //                             openInNewTab(
  //                               `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
  //                             )
  //                           }
  //                         >
  //                           Download Buku Panduan
  //                         </button>
  //                       </div>
  //                     </div>
  //                   );
  //                 })} */}
  //             </>
  //           ))}
  //         </div>
  //         <div class="f25r blue-text-1 monsterratExtraBold center768  mb-5 ">
  //           Pedoman Aplikasi KOINS HTS
  //         </div>

  //         <div
  //           class="w100 flex py-2 panduanMain  "
  //           style={{
  //             // justifyContent: "center",
  //             flexWrap: "wrap",
  //           }}
  //         >
  //           {Object.keys(panduanHTS).map((keyName, i) => (
  //             <>
  //               {/* <div class="f165r blue-text-1 monsterratBold center768  text-center ">
  //                 {keyName}
  //               </div> */}
  //               {panduanHTS[keyName].length == 1 ? (
  //                 <>
  //                   {" "}
  //                   {panduanHTS[keyName].map((panduan, i) => {
  //                     return (
  //                       <div class="panduanDetailContainer py-3 mb-5 text-center">
  //                         <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
  //                           {panduan.guidance_detail_title}
  //                         </span>
  //                         <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
  //                           <button
  //                             class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
  //                             style={{ borderRadius: 12 }}
  //                             onClick={(e) =>
  //                               openInNewTab(
  //                                 `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
  //                               )
  //                             }
  //                           >
  //                             Download Buku Panduan
  //                           </button>
  //                         </div>
  //                       </div>
  //                     );
  //                   })}
  //                 </>
  //               ) : (
  //                 <>
  //                   <div class="panduanDetailContainer py-3 mb-5 text-center">
  //                     <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
  //                       {keyName}
  //                     </span>
  //                     <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
  //                       <button
  //                         class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
  //                         style={{ borderRadius: 12 }}
  //                         onClick={(e) => PanduanModal2(e, panduanHTS[keyName])}
  //                       >
  //                         Download Buku Panduan
  //                       </button>
  //                     </div>
  //                   </div>
  //                 </>
  //               )}

  //               {/* {panduanMTS[keyName].map((panduan, i) => {
  //                   return (
  //                     <div class="panduanDetailContainer py-3 mb-5 text-center">
  //                       <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
  //                         {panduan.guidance_detail_title}
  //                       </span>
  //                       <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
  //                         <button
  //                           class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
  //                           style={{ borderRadius: 12 }}
  //                           onClick={(e) =>
  //                             openInNewTab(
  //                               `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
  //                             )
  //                           }
  //                         >
  //                           Download Buku Panduan
  //                         </button>
  //                       </div>
  //                     </div>
  //                   );
  //                 })} */}
  //             </>
  //           ))}
  //         </div>

  //       </div>
  //       <div className="kisiTalkContainer mb-5 pb-5">
  //         <div className="kisiTalkWrapper">
  //           <div class="f25r blue-text-1 monsterratExtraBold center768  text-center pb-3 mb-5 ">
  //             Video Tutorial Aplikasi KOINS HTS dan KOINS MTS
  //           </div>

  //           <div className="iframeKoinsContainer  center-vertical iframeHome">
  //             <iframe
  //               width="100%"
  //               height={window.innerHeight > 768 ? "auto" : "500px"}
  //               src="https://www.youtube.com/embed/DY_pBXGdzv4"
  //               title="YouTube video player"
  //               frameborder="0"
  //               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  //               allowFullScreen
  //               id="yt-koins"
  //               className="iframe-koins iframe-main-playlist"
  //             ></iframe>

  //             <div className="ytOtherContainer">
  //               <YouTubePlaylist
  //                 apiKey={ytApikey}
  //                 playlistId="PL0TzfdbrGZVEckifL-y7Ryirs-It6PZ7y"
  //                 uniqueName="youtube-playlist-container"
  //                 className="koinsPlaylist"
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  const PanduanKoinsHTS = () => {
    let panduanHTSArr = panduanList
      .filter((panduan) => panduan.guidance_product_id == 8)
      .filter((panduan) => panduan.file1_slug);
    let panduanHTS = {};

    for (let i = 0; i < panduanHTSArr.length; i++) {
      const element = panduanHTSArr[i];
      if (panduanHTS[element.guidance_category.guidance_category_title]) {
        panduanHTS[element.guidance_category.guidance_category_title].push(
          element
        );
      } else {
        panduanHTS[element.guidance_category.guidance_category_title] = [
          element,
        ];
      }
    }

    console.log(Object.keys(panduanHTS));

    return (
      <div class="mb-5 pb5">
        <div class=" mx-auto  panduanWrapper">
          <div class="f25r blue-text-1 monsterratExtraBold center768  text-center pb-3 mb-5 ">
            Pedoman Aplikasi KOINS MTS
          </div>

          <div
            class="w100 flex py-5 panduanMain  "
            style={{
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {Object.keys(panduanHTS).map((keyName, i) => (
              <>
                {/* <div class="f165r blue-text-1 monsterratBold center768  text-center ">
                  {keyName}
                </div> */}
                {panduanHTS[keyName].length == 1 ? (
                  <>
                    {" "}
                    {panduanHTS[keyName].map((panduan, i) => {
                      return (
                        <div class="panduanDetailContainer py-3 mb-5 text-center">
                          <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
                            {panduan.guidance_detail_title}
                          </span>
                          <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                            <button
                              class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
                              style={{ borderRadius: 12 }}
                              onClick={(e) =>
                                openInNewTab(
                                  `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
                                )
                              }
                            >
                              Download Buku Panduan
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div class="panduanDetailContainer py-3 mb-5 text-center">
                      <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
                        {keyName}
                      </span>
                      <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                        <button
                          class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
                          style={{ borderRadius: 12 }}
                          onClick={(e) => PanduanModal2(e, panduanHTS[keyName])}
                        >
                          Download Buku Panduan
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {/* {panduanMTS[keyName].map((panduan, i) => {
                    return (
                      <div class="panduanDetailContainer py-3 mb-5 text-center">
                        <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
                          {panduan.guidance_detail_title}
                        </span>
                        <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                          <button
                            class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
                            style={{ borderRadius: 12 }}
                            onClick={(e) =>
                              openInNewTab(
                                `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
                              )
                            }
                          >
                            Download Buku Panduan
                          </button>
                        </div>
                      </div>
                    );
                  })} */}
              </>
            ))}
          </div>
          {/* <table class="table table-bordered textCenter monsterratMedium lh25 panduanTable mb-5 pb-5 none-mobile">
            <thead>
              <tr></tr>
            </thead>

            <colgroup>
              <col colspan="1" style={{ width: "35%" }}></col>

              <col colspan="1" style={{ width: "16.25%" }}></col>
              <col colspan="1" style={{ width: "16.25%" }}></col>
            </colgroup>

            <tbody>
              <tr class="manualTableWidth">
                <th
                  class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-5"
                  rowspan="2"
                >
                  Penggunaan KOINS
                </th>
                <th
                  class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-4"
                  colspan="2"
                >
                  KOINS HTS (Windows)
                </th>
                <th
                  class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-4"
                  colspan="2"
                >
                  KOINS MTS (Mobile)
                </th>
              </tr>
              <tr class="manualTableWidth">
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 ">
                  Buku Pedoman
                </th>
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 ">
                  Sistem
                </th>
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 ">
                  Buku Pedoman
                </th>
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 ">
                  Sistem
                </th>
              </tr>
              <tr class="manualTableWidth">
                <th
                  class="table-center h100 monsterratMedium py18r blue-text-1 f11r"
                  rowspan="1"
                >
                  Menu dan Penjelasan<br></br>
                  All - In - One
                </th>
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(`${get_manual}13/manual_koins_hts`)
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>{" "}
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(host + `/download/KOINS_HTS_setup.exe`)
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>{" "}
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(`${get_manual}13/manual_koins_mts`)
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>{" "}
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={() => openInNewTab(detectKOINS())}
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>
            </tbody>
          </table> */}
          {/* <div class="mx-auto">
            {" "}
            <table
              style={{ width: "max-content" }}
              class="table table-bordered textCenter monsterratMedium lh25 panduanTable mb-4 pb-4 mx-auto none-desktop"
            >
              <thead>
                <tr></tr>
              </thead>

              <colgroup>
                <col colspan="2" style={{ width: "25%" }}></col>
                <col colspan="2" style={{ width: "25%" }}></col>
              </colgroup>

              <tbody>
                <tr class="manualTableWidth">
                  <th
                    class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-4"
                    colspan="2"
                  >
                    KOINS HTS (Windows)
                  </th>
                  <th
                    class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-4"
                    colspan="2"
                  >
                    KOINS MTS (Mobile)
                  </th>
                </tr>
                <tr class="manualTableWidth">
                  <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 ">
                    Buku Pedoman
                  </th>
                  <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 ">
                    Sistem
                  </th>
                  <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 ">
                    Buku Pedoman
                  </th>
                  <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 ">
                    Sistem
                  </th>
                </tr>
                <tr class="manualTableWidth">
                  <td class=" table-center h100 monsterratMedium">
                    <button
                      onClick={(e) =>
                        openInNewTab(`${get_manual}13/manual_koins_hts`)
                      }
                      class="f075r panduanButton"
                    >
                      Unduh{" "}
                    </button>
                  </td>{" "}
                  <td class=" table-center h100 monsterratMedium">
                    <button
                      onClick={(e) =>
                        openInNewTab(host + `/download/KOINS_HTS_setup.exe`)
                      }
                      class="f075r panduanButton"
                    >
                      Unduh{" "}
                    </button>
                  </td>{" "}
                  <td class=" table-center h100 monsterratMedium">
                    <button
                      onClick={(e) =>
                        openInNewTab(`${get_manual}13/manual_koins_mts`)
                      }
                      class="f075r panduanButton"
                    >
                      Unduh{" "}
                    </button>
                  </td>{" "}
                  <td class=" table-center h100 monsterratMedium">
                    <button
                      onClick={() => openInNewTab(detectKOINS())}
                      class="f075r panduanButton"
                    >
                      Unduh{" "}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </div>
        <div className="kisiTalkContainer mb-5 pb-5">
          <div className="kisiTalkWrapper">
            <div class="f25r blue-text-1 monsterratExtraBold center768  text-center pb-3 mb-5 ">
              Video Tutorial Aplikasi KOINS HTS dan KOINS MTS
            </div>

            <div className="iframeKoinsContainer  center-vertical iframeHome">
              <iframe
                width="100%"
                height={window.innerHeight > 768 ? "auto" : "500px"}
                src="https://www.youtube.com/embed/DY_pBXGdzv4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                id="yt-koins"
                className="iframe-koins iframe-main-playlist"
              ></iframe>

              <div className="ytOtherContainer">
                <YouTubePlaylist
                  apiKey={ytApikey}
                  playlistId="PL0TzfdbrGZVEckifL-y7Ryirs-It6PZ7y"
                  uniqueName="youtube-playlist-container"
                  className="koinsPlaylist"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const PanduanEIpo = () => {
    return (
      <div class=" mx-auto pb-5 mb-5 panduanWrapper">
        <div class="f25r blue-text-1 monsterratExtraBold center768  text-center pb-3 mb-5 ">
          Pedoman e-IPO
        </div>
        <div class="f165r blue-text-1 monsterratBold center768  text-center ">
          Menu dan Penjelasan All - In - One
        </div>
        <div
          class="w100 flex py-5 panduanMain  "
          style={{
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {panduanList
            .filter((panduan) => panduan.guidance_category_id == 7)
            .map((panduan, i) => {
              return (
                <div class="panduanDetailContainer py-3 mb-5 text-center">
                  <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
                    {panduan.guidance_detail_title}
                  </span>
                  <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                    <button
                      class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
                      style={{ borderRadius: 12 }}
                      onClick={(e) =>
                        openInNewTab(
                          `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
                        )
                      }
                    >
                      {panduan.file1_title}
                    </button>

                    <button
                      class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold "
                      style={{ borderRadius: 12 }}
                      onClick={(e) =>
                        openInNewTab(
                          `${get_manual}${panduan.guidance_detail_id}/${panduan.file2_slug}`
                        )
                      }
                    >
                      {panduan.file2_title}
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        {/* <table class="table table-bordered textCenter monsterratMedium lh25 panduanTable mb-5 pb-5">
          <thead>
            <tr></tr>
          </thead>

          <colgroup>
            <col colspan="1" style={{ width: "33.3%" }}></col>

            <col colspan="1" style={{ width: "33.3%" }}></col>
            <col colspan="1" style={{ width: "33.3%" }}></col>
          </colgroup>

          <tbody>
            <tr class="manualTableWidth">
              <th class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-5">
                Pedoman e-IPO
              </th>
              <th class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-4">
                Pendaftaran e-IPO
              </th>
              <th class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-4">
                Pemesanan e-IPO
              </th>
            </tr>
   
            <tr class="manualTableWidth">
              <th class="table-center h100 monsterratMedium py18r blue-text-1 f11r">
                Menu dan Penjelasan<br></br>All - In - One
              </th>
              <td class=" table-center h100 monsterratMedium">
                <button
                  onClick={(e) =>
                    openInNewTab(`${get_manual}16/manual_pendaftaran_e_ipo`)
                  }
                  class="f075r panduanButton"
                >
                  Unduh{" "}
                </button>
              </td>{" "}
              <td class=" table-center h100 monsterratMedium">
                <button
                  onClick={(e) =>
                    openInNewTab(`${get_manual}16/manual_pemesanan_e_ipo`)
                  }
                  class="f075r panduanButton"
                >
                  Unduh{" "}
                </button>
              </td>
            </tr>
          </tbody>
        </table> */}
      </div>
    );
  };
  const PanduanWebRegist = () => {
    return (
      <div>
        <div class=" mx-auto panduanWrapper">
          {/* <div class="f25r blue-text-1 monsterratExtraBold center768  text-center pb-3 mb-5 none-mobile">
            Panduan Pembukaan Akun Secara <i>Online</i> dan <i>Offline</i>
          </div> */}

          <div class="f25r blue-text-1 monsterratExtraBold text-center mb-5 ">
            Pedoman Web Registration
          </div>
          <div class="f165r blue-text-1 monsterratBold center768  text-center ">
            Menu dan Penjelasan All - In - One
          </div>
          {/* <div class="flex">
            <div>
              <img
                class=""
                style={{
                  height: "6rem",
                }}
                src={kisiMobile2}
              />
            </div>
            <div
              class="mx-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div class="blue-text-1 f15r monsterratBold "> KISI Mobile</div>

              <div
                class="flex "
                style={{
                  paddingBottom: 5,
                }}
              >
                <div class="text-center">
                  <img
                    onClick={() =>
                      openInNewTab(
                        "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                      )
                    }
                    class="downloadImgPanduan "
                    style={{ height: "3rem", marginRight: "1.3rem" }}
                    src={playStore}
                  />
                </div>
                <div class="text-center ">
                  <img
                    onClick={() =>
                      openInNewTab(
                        "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                      )
                    }
                    class="downloadImgPanduan "
                    style={{ height: "3rem" , marginRight: "1.3rem"}}
                    src={appStore}
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div
            class="w100 flex py-5 panduanMain  "
            style={{
              // justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {panduanList
              .filter((panduan) => panduan.guidance_category_id == 8)
              .map((panduan, i) => {
                return (
                  <div class="panduanDetailContainer py-3 mb-5 text-center">
                    <span class="  white monsterratRegular f14r text-center w100 px-3">
                      {panduan.guidance_detail_title}
                    </span>
                    <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                      <div
                        class="manualSub py-3 f085r monsterratLight"
                        style={{}}
                      >
                        <span style={{ color: "transparent" }}> </span>
                      </div>

                      <button
                        class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold"
                        style={{ borderRadius: 12 }}
                        onClick={(e) =>
                          openInNewTab(
                            `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
                          )
                        }
                      >
                        Download Buku Panduan
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* 
          <table class="table table-bordered textCenter monsterratMedium lh25 panduanTable mb-1 pb-3  ">
            <thead>
              <tr></tr>
            </thead>

            <colgroup>
              <col
                class="panduanOffline1"
                colspan="1"
                style={{ width: "40%" }}
              ></col>
              <col
                class="panduanOffline2"
                colspan="1"
                style={{ width: "30%" }}
              ></col>
              <col
                class="panduanOffline2"
                colspan="1"
                style={{ width: "30%" }}
              ></col>
            </colgroup>

            <tbody>
              <tr class="manualTableWidth">
                <th
                  rowspan="2"
                  class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-5"
                >
                  Jenis Akun
                </th>

                <th
                  class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 lh13 f13r  py-4"
                  colspan="2"
                >
                  KISI Mobile
                </th>
              </tr>
              <tr class="manualTableWidth">
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 f11r">
                  Buku Pedoman
                </th>
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 f11r">
                  <i> System</i>
                </th>
              </tr>
              <tr class="manualTableWidth">
                <td class=" table-center h100 monsterratMedium py18r blue-text-1 f11r">
                  Akun Reguler
                </td>

                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}1/opening_account_online_individu_reguler`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
                <td rowspan="5" class=" table-center h100 monsterratMedium">
                  <div class=" storeFooterDiv">
                    <div class="text-center">
                      <img
                        onClick={() =>
                          openInNewTab(
                            "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                          )
                        }
                        class="downloadImgPanduan mb-4"
                        style={{ height: "3rem" }}
                        src={playStore}
                      />
                    </div>
                    <div class="text-center">
                      <img
                        onClick={() =>
                          openInNewTab(
                            "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                          )
                        }
                        class="downloadImgPanduan mb-2"
                        style={{ height: "3rem" }}
                        src={appStore}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="manualTableWidth ">
                <td class=" table-center h100 monsterratMedium py18r blue-text-1 f11r relative">
                  Penambahan Akun Margin*
                  <div
                    class="f065r"
                    style={{ textAlign: "center", bottom: "1px" }}
                  >
                    {" "}
                    *Jika nasabah sudah memiliki Akun Reguler{" "}
                  </div>
                </td>

                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}2/opening_account_online_individu_add_margin`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>

              <tr class="manualTableWidth">
                <td class=" table-center h100 monsterratMedium py18r blue-text-1 f11r relative">
                  Akun Margin dan Reguler*
                  <div
                    class="f065r"
                    style={{ textAlign: "center", bottom: "25px" }}
                  >
                    {" "}
                    *Jika nasabah belum memiliki Akun Reguler{" "}
                  </div>
                </td>

                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}3/opening_account_online_individu_margin_reguler`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>
              <tr>
                <td class=" table-center h100 monsterratMedium py18r blue-text-1 f11r">
                  Akun Syariah
                </td>

                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}4/opening_account_online_individu_syariah`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>

              <tr class="manualTableWidth">
                <td class=" table-center h100 monsterratMedium py18r blue-text-1 f11r relative">
                  Penambahan Akun Syariah
                  <div
                    class="f065r"
                    style={{ textAlign: "center", bottom: "1px" }}
                  >
                    {" "}
                    *Jika nasabah sudah memiliki Akun Reguler{" "}
                  </div>
                </td>

                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}5/opening_account_online_individu_add_syariah`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="py-1"></div>
          <div class="f25r blue-text-1 monsterratExtraBold center768  text-center mt-5 py-5 pb-3 mb-5">
            Pedoman Pembukaan Akun Secara <i>Offline</i> (Pribadi)
          </div> */}
          {/* <table class="table table-bordered textCenter monsterratMedium lh25 panduanTable mb-3 pb-3 ">
            <thead>
              <tr>
      
              </tr>
            </thead>

            <colgroup>
              <col colspan="1" style={{ width: "17%" }}></col>
              <col colspan="1" style={{ width: "17%" }}></col>
              <col colspan="1" style={{ width: "33%" }}></col>
            </colgroup>

            <tbody>
              <tr class="manualTableWidth">
                <th
                  rowspan="2"
                  class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-5"
                >
                  Jenis Akun
                </th>
                <th
                  rowspan="2"
                  class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-5"
                >
                  Jenis BANK RDN
                </th>

                <th
                  class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 lh13 f13r  py-4"
                  colspan="2"
                >
                  Manual <br></br>
            
                </th>
              </tr>
              <tr class="manualTableWidth">
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 f11r">
                  Buku Pedoman
                </th>
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 f11r">
                  Formulir
                </th>
              </tr>
              <tr class="manualTableWidth">
                <th
                  class=" table-center h100 monsterratMedium blue-text-1 f11r"
                  rowspan="3"
                >
                  Akun Reguler<br></br>atau<br></br>Akun Margin
                </th>
                <td class=" table-center h100 monsterratMedium py18r blue-text-1 f11r">
                  RDN BCA
                </td>

                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}6/opening_account_offline_individu_bca`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}6/opening_account_offline_individu_bca_form`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>
              <tr class="manualTableWidth">
                <td class=" table-center h100 monsterratMedium py18r blue-text-1 f11r">
                  RDN MANDIRI
                </td>

                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}7/opening_account_offline_individu_mandiri`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}7/opening_account_offline_individu_mandiri_form`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>

              <tr class="manualTableWidth">
                <td class=" table-center h100 monsterratMedium py18r blue-text-1 f11r">
                  RDN CIMB NIAGA
                </td>

                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}8/opening_account_offline_individu_cimb`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}8/opening_account_offline_individu_cimb_form`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  class=" table-center h100 monsterratMedium blue-text-1 f11r"
                >
                  Akun Syariah
                </th>
                <td class=" table-center h100 monsterratMedium py18r blue-text-1 f11r">
                  RDN CIMB NIAGA Syariah
                </td>

                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}9/opening_account_offline_individu_syariah_cimb`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}9/opening_account_offline_individu_syariah_cimb_form`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="py-1"></div>
  
          <div class="f25r blue-text-1 monsterratExtraBold center768  text-center mt-5 pt-5 pb-3 mb-5">
            Pedoman Pembukaan Akun Secara <i>Offline</i> (Institusi)
          </div>
          <table class="table table-bordered textCenter monsterratMedium lh25 panduanTable mb-3 pb-3 ">
            <thead>
              <tr>
   
              </tr>
            </thead>

            <colgroup>
              <col colspan="1" style={{ width: "17%" }}></col>
              <col colspan="1" style={{ width: "17%" }}></col>
              <col colspan="1" style={{ width: "33%" }}></col>
            </colgroup>

            <tbody>
              <tr class="manualTableWidth">
                <th
                  rowspan="2"
                  class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-5"
                >
                  Jenis Akun
                </th>
                <th
                  rowspan="2"
                  class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-5"
                >
                  Jenis BANK RDN
                </th>

                <th
                  class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 lh13 f13r  py-4"
                  colspan="2"
                >
                  Manual <br></br>
     
                </th>
              </tr>
              <tr class="manualTableWidth">
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 f11r">
                  Buku Pedoman
                </th>
                <th class=" table-center h100 monsterratMedium table-lightblue-bg blue-text-1 f11r">
                  Formulir
                </th>
              </tr>
              <tr class="manualTableWidth">
                <th
                  class=" table-center h100 monsterratMedium blue-text-1 f11r"
                  rowspan="3"
                >
                  Akun Reguler<br></br>atau<br></br>Akun Margin
                </th>
                <td class=" table-center h100 monsterratMedium py18r blue-text-1 f11r">
                  RDN BCA
                </td>

                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}10/opening_account_offline_individu_syariah_cimb`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}10/opening_account_offline_individu_syariah_cimb`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>
              <tr class="manualTableWidth">
                <td class=" table-center h100 monsterratMedium py18r blue-text-1 f11r">
                  RDN MANDIRI
                </td>

                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}11/opening_account_online_institusi_mandiri`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}11/opening_account_online_institusi_mandiri_form`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>

              <tr class="manualTableWidth">
                <td class=" table-center h100 monsterratMedium py18r blue-text-1 f11r">
                  RDN CIMB NIAGA
                </td>

                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}12/opening_account_online_institusi_cimb`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
                <td class=" table-center h100 monsterratMedium">
                  <button
                    onClick={(e) =>
                      openInNewTab(
                        `${get_manual}12/opening_account_online_institusi_cimb_form`
                      )
                    }
                    class="f075r panduanButton"
                  >
                    Unduh{" "}
                  </button>
                </td>
              </tr>
            </tbody>
          </table> */}
          {/* <div className="kisiTalkContainer mb-5 pb-5">
            <div className="kisiTalkWrapper">
              <div class="f25r blue-text-1 monsterratExtraBold center768  text-center pb-3 mb-5 ">
                Video Tutorial Aplikasi KISI Mobile
              </div>

              <div className="center-vertical iframeHome">
                <iframe
                  width="100%"
                  height={window.innerHeight > 768 ? "auto" : "500px"}
                  src="https://www.youtube.com/embed/W4QEWbHLiUQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  id="yt-koins"
                  className="iframe-koins iframe-main-playlist"
                ></iframe>

                <div className="ytOtherContainer">
                  <YouTubePlaylist
                    apiKey={ytApikey}
                    playlistId="PL0TzfdbrGZVHcyZ9AciGMisFnCApcXd4w"
                    uniqueName="youtube-playlist-container"
                    className="koinsPlaylist"
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  };
  const PanduanKisiMobile = () => {
    return (
      <div class=" mx-auto pb-5 mb-5 panduanWrapper">
        <div class="f25r blue-text-1 monsterratExtraBold center768  text-center pb-3 mb-5 ">
          Pedoman Penggunaan Aplikasi KISI Mobile
        </div>{" "}
        <div class="f165r blue-text-1 monsterratBold center768  text-center ">
          Menu dan Penjelasan All - In - One
        </div>
        <div
          class="w100 flex py-5 panduanMain  "
          style={{
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {panduanList
            .filter((panduan) => panduan.guidance_category_id == 5)
            .map((panduan, i) => {
              return (
                <div class="panduanDetailContainer py-3 mb-5 text-center">
                  <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
                    {panduan.guidance_detail_title}
                  </span>
                  <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                    <button
                      class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
                      style={{ borderRadius: 12 }}
                      onClick={(e) =>
                        openInNewTab(
                          `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
                        )
                      }
                    >
                      Download Buku Pedoman
                    </button>

                    <button
                      class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold "
                      style={{ borderRadius: 12 }}
                      onClick={(e) =>
                        openInNewTab(
                          `${get_manual}${panduan.guidance_detail_id}/${panduan.file2_slug}`
                        )
                      }
                    >
                      Reset & Change Password
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        {/* <table class="table table-bordered textCenter monsterratMedium lh25 panduanTable mb-5 pb-5">
          <thead>
            <tr></tr>
          </thead>

          <colgroup>
            <col colspan="1" style={{ width: "33.3%" }}></col>

            <col colspan="1" style={{ width: "33.3%" }}></col>
            <col colspan="1" style={{ width: "33.3%" }}></col>
          </colgroup>

          <tbody>
            <tr class="manualTableWidth">
              <th class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-5">
                Pedoman Penggunaan Aplikasi KISI Mobile
              </th>
              <th class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-4">
                Pedoman Penggunaan Aplikasi KISI Mobile
              </th>
              <th class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-4">
                Reset & Change Password
              </th>
            </tr>
           
            <tr class="manualTableWidth">
              <th class="table-center h100 monsterratMedium py18r blue-text-1 f11r">
                Menu dan Penjelasan<br></br>All - In - One
              </th>
              <td class=" table-center h100 monsterratMedium">
                <button
                  onClick={(e) =>
                    openInNewTab(`${get_manual}14/manual_kisi_mobile`)
                  }
                  class="f075r panduanButton"
                >
                  Unduh{" "}
                </button>
              </td>{" "}
              <td class=" table-center h100 monsterratMedium">
                <button
                  onClick={(e) =>
                    openInNewTab(
                      `${get_manual}14/manual_kisi_mobile_reset_password`
                    )
                  }
                  class="f075r panduanButton"
                >
                  Unduh{" "}
                </button>
              </td>
            </tr>
          </tbody>
        </table> */}
        <div class="f25r blue-text-1 monsterratExtraBold center768  text-center pb-3 mb-5 mt-4 pt-4">
          Pedoman Penggunaan Fitur KINDS
        </div>
        <div class="f165r blue-text-1 monsterratBold center768  text-center ">
          Menu dan Penjelasan All - In - One
        </div>
        <div
          class="w100 flex py-5 panduanMain  "
          style={{
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {panduanList
            .filter((panduan) => panduan.guidance_category_id == 6)
            .map((panduan, i) => {
              return (
                <div class="panduanDetailContainer py-3 mb-5 text-center">
                  <span class="  white monsterratRegular f14r text-center w100 px-3 mb-4">
                    {panduan.guidance_detail_title}
                  </span>
                  <div class="promoDetailWrapper py-1 px-5 white monsterratRegular f14r text-center">
                    <button
                      class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold mb-3"
                      style={{ borderRadius: 12 }}
                      onClick={(e) =>
                        openInNewTab(
                          `${get_manual}${panduan.guidance_detail_id}/${panduan.file1_slug}`
                        )
                      }
                    >
                      Download Buku Pedoman
                    </button>

                    <button
                      class="w100 f1r py-2 blue-text-1 manualButton monsterratSemiBold "
                      style={{ borderRadius: 12 }}
                      onClick={(e) =>
                        openInNewTab(
                          `${get_manual}${panduan.guidance_detail_id}/${panduan.file2_slug}`
                        )
                      }
                    >
                      Order Reksa Dana
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        {/* <table class="table table-bordered textCenter monsterratMedium lh25 panduanTable mb-5 pb-5">
          <thead>
            <tr></tr>
          </thead>

          <colgroup>
            <col colspan="1" style={{ width: "33.3%" }}></col>

            <col colspan="1" style={{ width: "33.3%" }}></col>
            <col colspan="1" style={{ width: "33.3%" }}></col>
          </colgroup>

          <tbody>
            <tr class="manualTableWidth">
              <th class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-5">
                Pedoman KINDS
              </th>
              <th class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-4">
                Pedoman Penggunaan Fitur KINDS
              </th>
              <th class=" table-center h100 monsterratMedium f13r table-lightblue-bg blue-text-1 py-4">
                Order Reksa Dana
              </th>
            </tr>
     
            <tr class="manualTableWidth">
              <th class="table-center h100 monsterratMedium py18r blue-text-1 f11r">
                Menu dan Penjelasan<br></br>All - In - One
              </th>
              <td class=" table-center h100 monsterratMedium">
                <button
                  onClick={(e) => openInNewTab(`${get_manual}15/manual_kinds`)}
                  class="f075r panduanButton"
                >
                  Unduh{" "}
                </button>
              </td>{" "}
              <td class=" table-center h100 monsterratMedium">
                <button
                  onClick={(e) =>
                    openInNewTab(`${get_manual}15/manual_order_kinds`)
                  }
                  class="f075r panduanButton"
                >
                  Unduh{" "}
                </button>
              </td>
            </tr>
          </tbody>
        </table> */}
      </div>
    );
  };
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const contentSwitcher = () => {
    switch (page) {
      case "pembukaan_akun":
        return PanduanOpeningAcc();
        break;
      case "koins":
        return PanduanKoins();
        break;
      // case "koins_mts":
      //   return PanduanKoinsMTS();
      //   break;
      // case "koins_hts":
      //   return PanduanKoinsHTS();
      //   break;
      case "web_registration":
        return PanduanWebRegist();
        break;
      case "e_ipo":
        return PanduanEIpo();
        break;
      case "kisi_mobile":
        return PanduanKisiMobile();
        break;
      default:
        break;
    }
  };
  const productList = [
    {
      name: "Panduan Pembukaan Akun",
      id: "panduan_pembukaan_akun",
      path: "/panduan/pembukaan_akun",
    },
    {
      name: "Panduan Penggunaan Aplikasi Koins",
      id: "panduan_koins",
      path: "/panduan/koins",
    },
    // {
    //   name: "Panduan Penggunaan Aplikasi Koins MTS",
    //   id: "panduan_koins_mts",
    //   path: "/panduan/koins_mts",
    // },
    // {
    //   name: "Panduan Penggunaan Aplikasi KoinsHTS",
    //   id: "panduan_koins_hts",
    //   path: "/panduan/koins_hts",
    // },

    {
      name: "Panduan KISI Mobile",
      id: "panduan_kisi_mobile",
      path: "/panduan/kisi_mobile",
    },
    {
      name: "Pedoman Penggunaan Web Registration",
      id: "panduan_web_registration",
      path: "/panduan/web_registration",
    },
    { name: "Panduan e-IPO", id: "panduan_e_ipo", path: "/panduan/e_ipo" },
  ];
  return (
    <>
      <div class="subPathWrapper  pb-5">
        <div class="none-desktop">
          <select
            onChange={(e) => console.log(navigate(e.target.value))}
            class="form-select subPathSelect monsterratMedium f12r"
            aria-label="Default select example"
          >
            {productList.map((product, i) => {
              if (page == product.id) {
                return (
                  <option selected class="subPathOption" value={product.path}>
                    {product.name}
                  </option>
                );
              } else {
                return (
                  <option class="subPathOption" value={product.path}>
                    {product.name}
                  </option>
                );
              }
            })}
          </select>
        </div>
        <div class="subPathContainer appPageLocation none-mobile">
          <div
            className="subPath  panduanSubPath monsterratMedium rounded-pill mx-3 px-3"
            id="pembukaan_akun"
            onClick={() => navigate("/panduan/pembukaan_akun")}
          >
            Pedoman Pembukaan Akun
          </div>
          <div
            className="subPath  panduanSubPath monsterratMedium rounded-pill mx-3 px-3"
            id="koins"
            onClick={() => navigate("/panduan/koins")}
          >
            Pedoman Penggunaan Aplikasi KOINS
          </div>
          {/* <div
            className="subPath  panduanSubPath monsterratMedium rounded-pill mx-3 px-3"
            id="koins_mts"
            onClick={() => navigate("/panduan/koins_mts")}
          >
            Pedoman Penggunaan Aplikasi KOINS MTS
          </div>
          <div
            className="subPath  panduanSubPath monsterratMedium rounded-pill mx-3 px-3"
            id="koins_hts"
            onClick={() => navigate("/panduan/koins_hts")}
          >
            Pedoman Penggunaan Aplikasi KOINS HTS
          </div> */}
          <div
            className="subPath  panduanSubPath monsterratMedium rounded-pill mx-3 px-3"
            id="kisi_mobile"
            onClick={() => navigate("/panduan/kisi_mobile")}
          >
            Pedoman Penggunaan Aplikasi KISI Mobile
          </div>
          <div
            className="subPath  panduanSubPath monsterratMedium rounded-pill mx-3 px-3"
            id="web_registration"
            onClick={() => navigate("/panduan/web_registration")}
          >
            Pedoman Penggunaan Web Registration
          </div>
          {/* <div
            className="subPath  panduanSubPath monsterratMedium rounded-pill mx-3 px-3"
            id="kinds"
            onClick={() => navigate("/panduan/kinds")}
          >
            Panduan Penggunaan Aplikasi KINDS
          </div> */}
          <div
            className="subPath  panduanSubPath monsterratMedium rounded-pill mx-3 px-3"
            id="e_ipo"
            onClick={() => navigate("/panduan/e_ipo")}
          >
            Pedoman e-IPO
          </div>
        </div>
      </div>

      <div class="w100 text-center">
        <img class="w70 panduanImg mx-auto" src={panduanImg} />
      </div>
      {contentSwitcher()}
    </>
  );
}
