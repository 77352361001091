// export const host = "http://localhost:8000"
//export const host = "https://sandbox.kisi.co.id/cms"
//export const server = "https://sandbox.kisi.co.id/cms"
// export const kisimobileServer = "https://sandbox.kisi.co.id/kisimobile/api/v2"
export const kisimobileServer = "https://mobile.kisi.co.id/api"
 export const host = "https://kisi.co.id"
 export const server = "https://kisi.co.id"
export const storageLocation = "/storage/"
// export const get_manual = `${host}/cms/api/v1/guidance/download/`
export const get_manual = `${host}/api/v1/guidance/download/`
// export const host = "https://sandbox.kisi.co.id/cms"
// export const storageLocation = "/kisimobile/storage/"
