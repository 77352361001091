import "./style.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fbLogo from "../../assets/fb.ico";
import instagramLogo from "../../assets/instagram.ico";
import twitterLogo from "../../assets/twitter.png";
import linkedinLogo from "../../assets/linkedin.ico";
import tiktokLogo from "../../assets/tiktok.ico";
export default function FollowSocmed(props) {
  useEffect(() => {
    if (props.subPath !== undefined && props.subPath !== "") {
      console.log(document.getElementsByClassName("subPathButton"));
      let test;
      console.log(document.getElementById(props.subPath), test);
      test = document.getElementById(props.subPath);
      document.getElementById(props.subPath).className +=
        " subPathButtonActive";
    }
  });
  const navigate = useNavigate();

  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  return (
    <div class="followSocmedContainer">
      <div class="followSocmedWrapper bg-biru-muda py-5">
        {/* <div class="grey monsterratMedium f15r">
          Download buku panduan untuk pendaftaran KOINS
        </div>
        <div>
          <button class="followSocmedButton monsterratBlack f12r"> DOWNLOAD</button>
        </div> */}
        <div class="monsterratExtraBold f2r blue-text-1 mb-2  followTitle">
   Ikuti Media Sosial Kami!
        </div>
        <div class="grey monsterratMedium f12r">
          Dapatkan informasi & konten menarik di social media kami
        </div>
        {/* <div class="monsterratRegular grey followText">
        Dapatkan informasi & konten yang menarik di social media kami
      </div> */}
        <div class="flex followSocmedImgContainer">
          <div class="flex mx-auto ">
            <div>
              <img
                class="socmed"
                src={instagramLogo}
                onClick={() => {
                  openInNewTab("https://www.instagram.com/kisi_sekuritas/");
                }}
              />
              <img
                class="socmed"
                src={tiktokLogo}
                onClick={() => {
                  openInNewTab("https://www.tiktok.com/@kisi_sekuritas");
                }}
              />
              <img
                class="socmed "
                src={fbLogo}
                onClick={() => {
                  openInNewTab("https://www.facebook.com/koreainvestment.id/");
                }}
              />
              <img
                class="socmed "
                src={linkedinLogo}
                onClick={() => {
                  openInNewTab("https://www.linkedin.com/company/korea-investment-and-sekuritas-indonesia-pt/mycompany/");
                }}
              />

              <img
                class="socmed twitFil"
                src={twitterLogo}
                onClick={() => {
                  openInNewTab("https://x.com/kisi_sekuritas");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
