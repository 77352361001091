import "./style.css";
import React, { useEffect, useState, useRef } from "react";

import marginTimeline from "../../assets/marginTimeline.jfif";
import {
  useNavigate,
  useParams,
  Navigate,
  useLocation,
} from "react-router-dom";
export default function KebijakanPrivasi(props) {
  const discRef = useRef(null);
  const kebRef = useRef(null);
  const infoRef = useRef(null);
  const { state } = useLocation();
  const path = window.location.pathname;
  let page;
  if (state) {
    page = state.page;
  }

  const GeneralDisclaimer = () => {
    return (
      <>
        <div class="disclaimerPageContainer mt-5 py-4">
          <div
            ref={discRef}
            style={{textAlign:'justify'}}
            class="disclaimerContainer rounded-corner bg-biru-muda px-5 py-5 mx-auto my-5   monsterratMedium f1r"
          >
            <h3 class="f blue-text-1 text-center monsterratExtraBold  pb-3">
              KEBIJAKAN PRIVASI
            </h3>
            <p>
              Dalam rangka meningkatkan layanan PT Korea Investment And
              Sekuritas Indonesia (“KISI”), kami berkomitmen untuk melindungi
              dan menjaga informasi mengenai data pribadi yang teridentifikasi
              atau dapat diidentifikasi secara tersendiri atau dikombinasi
              dengan informasi lainnya baik secara langsung maupun tidak
              langsung melalui sistem elektronik atau non-elektronik (“Data
              Pribadi”).
            </p>
            <p>
              Sesuai dengan ketentuan sebagaimana diatur dalam Undang-Undang
              Nomor 27 Tahun 2022 tentang Pelindungan Data Pribadi (”UU PDP”).
              Kebijakan Privasi ini merupakan standar yang diterapkan oleh KISI
              dalam melakukan pemrosesan atas Data Pribadi Anda dengan tetap
              memperhatikan prinsip-prinsip pemrosesan Data Pribadi sesuai
              dengan ketentuan yang berlaku.
            </p>
            <ol type="A">
              <li
                style={{ fontSize: "1.5rem" }}
                class="monsterratSemiBold blue-text-1"
              >
                {" "}
                <h4 class="monsterratSemiBold blue-text-1">
                  Ruang Lingkup Data Pribadi
                </h4>
              </li>
              <p>
                Data Pribadi yang dikumpulkan oleh KISI sehubungan dengan
                penyediaan produk dan jasa/layanan, meliputi Data Pribadi yang
                bersifat spesifik dan Data Pribadi yang bersifat umum.
              </p>
              <ol>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >
                  <>Data Pribadi yang bersifat spesifik </>
                </li>
                <p>Data Pribadi yang bersifat spesifik, antara lain:</p>
                <ul>
                  <li>
                    <p>
                      Data biometerik yang memungkinkan identifikasi unik
                      terhadap individu, seperti gambar wajah, rekam sidik jari;
                    </p>
                  </li>
                  <li>
                    <p>
                      Data keuangan pribadi, seperti riwayat transaksi,
                      informasi saldo efek dan saldo kas pada bank Rekening Dana
                      Nasabah (”<strong>RDN</strong>”); dan
                    </p>
                  </li>
                  <li>
                    <p>
                      Data lainnya sesuai dengan ketentuan peraturan
                      perundang-undangan.
                    </p>
                  </li>
                </ul>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >
                  <>Data Pribadi yang bersifat umum </>
                </li>
                <p>Data Pribadi yang bersifat umum, antara lain:</p>
                <ul>
                  <li>Nama lengkap;</li>
                  <li>Jenis kelamin;</li>
                  <li>Kewarganegaraan;</li>
                  <li>Agama;</li>
                  <li>Status perkawinan;</li>
                  <li>Alamat;</li>
                  <li>Tempat dan tanggal lahir;</li>
                  <li>Nama gadis ibu kandung;</li>
                  <li>Pekerjaan;</li>
                  <li>
                    Data pribadi yang dikombinasikan untuk mengidentifikasi
                    seseorang, seperti nomor telepon seluler dan{" "}
                    <em>IP Address.</em>
                  </li>
                </ul>
              </ol>
              <li
                style={{ fontSize: "1.5rem" }}
                class="monsterratSemiBold blue-text-1"
              >
                {" "}
                <h4 class="monsterratSemiBold blue-text-1">
                  Bagaimana KISI Memperoleh dan Mengumpulkan Data Pribadi
                </h4>
              </li>
              <p>
                KISI memperoleh dan mengumpulkan Data Pribadi melalui berbagai
                sarana dan aktivitas antara lain:
              </p>
              <ol>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >
                  <>
                    <>
                      <em>Website</em>
                    </>
                    <>, Aplikasi, dan Formulir</>
                  </>
                </li>
                <p>
                  KISI dapat memperoleh dan mengumpulkan Data Pribadi dari
                  perangkat yang Anda gunakan pada saat mengakses{" "}
                  <em>website</em> atau aplikasi yang disediakan oleh KISI, dan
                  dari permohonan layanan perbankan yang Anda ajukan baik dengan
                  mengisi formulir, melengkapi data permohonan melalui{' '}
                <em>website</em> , aplikasi KISI, dan media lainnya yang
                  disediakan oleh KISI maupun pihak yang bekerja sama dengan
                  KISI.
                </p>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >
                  {" "}
                  Komunikasi dan <em>Interaksi</em>
                </li>
                <p>
                  KISI dapat memperoleh dan mengumpulkan Data Pribadi saat Anda
                  melakukan komunikasi dan interaksi dengan KISI, antara lain
                  pada saat Anda menghubungi
                  {' '}<em>Call Center</em> KISI, menjawab pertanyaan, memberikan
                  informasi atau masukan, atau berpartisipasi dalam promosi,
                  kontes, survei, atau konferensi yang diselenggarakan oleh
                  KISI.
                </p>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >
                  {" "}
                  Aktivitas <em>Transaksi</em>
                </li>
                <p>
                  KISI mengumpulkan informasi atas aktivitas transaksi Anda saat
                  Anda dan/atau perwakilan resmi Anda melakukan transaksi dengan
                  atau melalui sarana yang disediakan oleh KISI.
                </p>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >
                  {" "}
                  <>
                    <em>Media</em>{' '}
                  </>
                  <>
                    Sosial dan Forum <em>Online</em>
                  </>
                </li>
                <p>
                  KISI dapat memperoleh dan mengumpulkan Data Pribadi melalui
                  {' '} <em>platform</em> dan situs media sosial KISI yang Anda
                  gunakan atau melalui forum <em>online</em> KISI yang Anda
                  akses.
                </p>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >
                  <>
                    <em>Lokasi</em>
                  </>{" "}
                  <>Fisik</></li>
                <p>
                  KISI dapat memperoleh dan mengumpulkan Data Pribadi baik secara
                  langsung maupun tidak langsung, ketika Anda dan/atau perwakilan
                  resmi Anda mengunjungi cabang, kantor, dan lokasi fisik lainnya
                  yang berkaitan dengan KISI.
                </p>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >
                  <>
                    Afiliasi dan <em>Pihak</em> Ketiga
                  </></li><p>
                  KISI dapat memperoleh dan mengumpulkan Data Pribadi dari pihak
                  terafiliasi KISI atau pihak lainnya yang bekerja sama dengan KISI.
                </p>
              </ol>
              <li
                style={{ fontSize: "1.5rem" }}
                class="monsterratSemiBold blue-text-1"
              >
                {" "}
                <h4 class="monsterratSemiBold blue-text-1">
                  Tujuan Pemrosesan Data Pribadi
                </h4>
              </li>
              <p>KISI akan melakukan pemrosesan atas Data Pribadi yang diperoleh maupun dikumpulkan oleh KISI untuk tujuan antara lain:</p>
              <ol>
                <li>Penyediaan layanan dan/atau jasa terkait produk pasar modal KISI bagi nasabah;</li>
                <li>Menjalankan prinsip Customer Due Diligence (CDD) sesuai ketentuan hukum yang berlaku;</li>
                <li>Memberikan personalisasi layanan dan/atau jasa terkait produk pasar modal yang KISI sediakan untuk Anda;</li>
                <li>Kepatuhan terhadap ketentuan hukum yang berlaku termasuk namun tidak terbatas pada Anti Pencucian Uang, Pencegahan Pendanaan Terorisme, dan Pencegahan Pendanaan Proliferasi Senjata Pemusnah Massal (APU-PPT dan PPPSPM);</li>
                <li>Menyelesaikan setiap permasalahan terkait akses Anda terhadap layanan dan/atau jasa terkait produk pasar modal KISI;</li>
                <li>Menindaklanjuti setiap keluhan atas layanan dan/atau jasa terkait produk pasar modal KISI atau pihak lain yang bekerja sama dengan KISI;</li>
                <li>Evaluasi, peningkatan, dan/atau pembaruan fitur layanan dan/atau jasa terkait produk pasar modal KISI dari waktu ke waktu;</li>
                <li>Penawaran iklan/promosi maupun pemasaran atas layanan dan/atau jasa terkait produk pasar modal KISI atau pihak lain yang bekerja sama dengan KISI dan penyelenggaraan loyalty program, kepada nasabah seperti gift, undian, dan lainnya; dan</li>
                <li>Tujuan-tujuan lainnya selama diizinkan atau diwajibkan oleh ketentuan hukum yang berlaku.</li>
              </ol>
              <li
                style={{ fontSize: "1.5rem" }}
                class="monsterratSemiBold blue-text-1"
              >
                {" "}
                <h4 class="monsterratSemiBold blue-text-1">
                  Pengungkapan Data Pribadi
                </h4>
              </li>
              <p>
                KISI dapat mengungkapkan Data Pribadi Anda kepada afiliasi KISI
                atau pihak lainnya yang bekerja sama dengan KISI, baik yang
                berlokasi di Indonesia atau di negara lain, untuk melaksanakan
                tujuan pemrosesan Data Pribadi sebagaimana dimaksud dalam
                ketentuan Kebijakan Privasi ini. Dalam hal KISI mengungkapkan Data
                Pribadi Anda kepada afiliasi KISI atau pihak lainnya yang bekerja
                sama dengan KISI, KISI akan meminta kepada afiliasi KISI atau
                pihak lainnya yang bekerja sama dengan KISI untuk menjaga
                kerahasiaan dan keamanan atas Data Pribadi Anda sesuai dengan
                ketentuan yang berlaku.
              </p>





              <li
                style={{ fontSize: "1.5rem" }}
                class="monsterratSemiBold blue-text-1"
              >
                {" "}
                <h4 class="monsterratSemiBold blue-text-1">
                  Hak Subjek Data Pribadi
                </h4>
              </li>
              <ol>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >Hak atas Informasi dan Akses</li>
                <p>
                  Anda memiliki hak untuk memperoleh informasi mengenai identitas
                  pihak yang meminta Data Pribadi Anda, tujuan permintaannya, serta
                  akses terhadap salinan Data Pribadi Anda. KISI akan memberikan
                  akses ke informasi tersebut melalui sarana resmi KISI, seperti
                  cabang KISI atau <em>channel</em> lainnya, sesuai dengan ketentuan
                  peraturan perundang-undangan dan kebijakan KISI.
                </p>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >Hak atas Perbaikan Data</li>
                <p>
                  Anda memiliki hak untuk melengkapi, memperbarui, dan/atau
                  memperbaiki Data Pribadi yang salah atau tidak akurat.
                </p>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                > Hak untuk Mendapatkan, Menggunakan dan/atau Mengirimkan Data
                  Pribadi ke Pihak Lain</li>
                <p>
                  Anda memiliki hak untuk memperoleh, memanfaatkan, atau memberikan
                  Data Pribadi Anda yang ada pada KISI kepada pihak ketiga, selama
                  sistem komunikasi yang digunakan oleh KISI dan Pihak Ketiga yang
                  dimaksud aman.
                </p>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                > Hak untuk Mengakhiri Pemrosesan, Menghapus dan/atau Memusnahkan
                  Data Pribadi</li>
                <p>
                  Anda berhak untuk mengakhiri pemrosesan, menghapus dan/atau
                  memusnahkan Data Pribadi Anda. Anda setuju untuk memberikan KISI
                  waktu untuk memproses pengakhiran pemrosesan, penghapusan dan/atau
                  pemusnahan Data Pribadi Anda sejauh KISI perlukan. Untuk
                  menjalankan hak mengakhiri pemrosesan, penghapusan dan/atau
                  pemusnahan Data Pribadi tersebut, Anda dapat menghubungi KISI
                  melalui sarana komunikasi yang diatur dalam Kebijakan Privasi ini.
                </p>
                <p>
                  Untuk dipahami, pengakhiran pemrosesan, penghapusan dan/atau
                  pemusnahan Data Pribadi tersebut dapat mempengaruhi kemampuan KISI
                  untuk menyediakan produk, layanan, dan jasa kepada Anda serta
                  hubungan kontraktual yang telah dibuat antara KISI dengan Anda
                  ataupun antara KISI dengan pihak ketiga lainnya, termasuk dapat
                  mengakibatkan terhentinya layanan yang Anda terima dan/atau
                  terjadinya pengakhiran atas satu atau beberapa perjanjian Anda
                  dengan KISI dan/atau pelanggaran terhadap satu atau beberapa
                  kewajiban Anda berdasarkan perjanjian dengan KISI.
                </p>
                <p>
                  Sehubungan dengan hal tersebut, pengakhiran pemrosesan,
                  penghapusan dan/atau pemusnahan Data Pribadi mengakibatkan Anda
                  memberikan hak kepada KISI untuk melakukan pemblokiran Rekening
                  Efek Nasabah (”<strong>REN</strong>”) dan/atau RDN Anda, dan/atau
                  menyatakan bahwa utang dan/atau kewajiban Anda kepada KISI menjadi
                  jatuh tempo dan dapat ditagih. Segala kerugian yang timbul akibat
                  pelaksanaan hak Anda untuk mengakhiri pemrosesan, penghapusan
                  dan/atau pemusnahan Data Pribadi merupakan tanggung jawab Anda.
                </p>
                <p>
                  Kewajiban KISI untuk menghapus dan memusnahkan Data Pribadi Anda
                  dikecualikan untuk:
                </p>

                <ul>
                  <li>
                    <p align="left"> Kepentingan pertahanan dan keamanan nasional;</p>
                  </li>
                  <li>
                    <p align="left"> Kepentingan proses penegakan hukum;</p>
                  </li>
                  <li>
                    <p align="left">
                       Kepentingan umum dalam rangka penyelenggaraan negara; atau
                    </p>
                  </li>
                  <li>
                    <p align="left">
                       Kepentingan pengawasan sektor jasa keuangan, moneter, sistem
                      pembayaran, dan stabilitas sistem keuangan yang dilakukan dalam
                      rangka penyelenggaraan negara. <br />
                      <br />
                    </p>
                  </li>
                </ul>
















                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >Hak untuk Menarik Persetujuan</li>
                <p>
                  Anda berhak untuk menarik kembali persetujuan pemrosesan Data
                  Pribadi yang telah Anda berikan kepada KISI, dan Anda setuju untuk
                  memberikan KISI tambahan waktu untuk memproses pengakhiran
                  pemrosesan Data Pribadi Anda sejauh KISI perlukan. Untuk
                  menjalankan hak penarikan persetujuan tersebut, Anda dapat
                  menghubungi KISI melalui sarana komunikasi yang diatur dalam
                  Kebijakan Privasi ini.
                </p>
                <p>
                  Anda perlu memahami bahwa penarikan persetujuan tersebut dapat
                  mempengaruhi kemampuan KISI untuk menyediakan produk, layanan, dan
                  jasa kepada Anda serta mengelola hubungan kontraktual yang telah
                  dibuat antara KISI dengan Anda ataupun antara KISI dengan pihak
                  ketiga lainnya termasuk dapat mengakibatkan terhentinya layanan
                  yang Anda terima dan/atau terjadinya pengakhiran atas satu atau
                  beberapa perjanjian Anda dengan KISI dan/atau pelanggaran terhadap
                  satu atau beberapa kewajiban Anda berdasarkan perjanjian dengan
                  KISI.
                </p>
                <p>
                  Sehubungan dengan hal tersebut, penarikan persetujuan pemrosesan
                  Data Pribadi tersebut mengakibatkan Anda memberikan hak kepada
                  KISI untuk melakukan pemblokiran REN dan/atau RDN, dan/atau
                  menyatakan bahwa utang dan/atau kewajiban Anda kepada KISI menjadi
                  jatuh tempo dan dapat ditagih. Segala kerugian yang timbul akibat
                  pelaksanaan hak Anda untuk menarik persetujuan pemrosesan Data
                  Pribadi merupakan tanggung jawab Anda.
                </p>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >Hak Mengajukan Keberatan atas Hasil Pemrosesan Otomatis</li>
                <p>
                  Anda berhak mengajukan keberatan atas hasil pemrosesan Data
                  Pribadi Anda yang dilakukan secara otomatis yang menimbulkan
                  akibat hukum atau berdampak signifikan terhadap Anda, termasuk
                  pemprofilan dan/atau penilaian resiko profil Anda.
                </p>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >Hak untuk Menunda atau Membatasi Pemrosesan</li>
                <p>
                  Anda berhak untuk menunda atau membatasi pemrosesan Data Pribadi
                  Anda secara proporsional sesuai dengan tujuan pemrosesan Data
                  Pribadi Anda. Untuk pelaksanaan hak ini, Anda dapat menghubungi
                  KISI melalui sarana komunikasi yang diatur dalam Kebijakan Privasi
                  ini. Anda perlu memahami bahwa permintaan penundaan atau
                  pembatasan pemrosesan tersebut dapat mempengaruhi kemampuan KISI
                  untuk menyediakan produk, layanan, dan jasa kepada Anda, serta
                  hubungan kontraktual yang telah dibuat antara KISI dengan Anda
                  ataupun antara KISI dengan pihak ketiga lainnya termasuk dapat
                  mengakibatkan terhentinya layanan yang Anda terima dan/atau
                  terjadinya pengakhiran atas satu atau beberapa perjanjian Anda
                  dengan KISI dan/atau pelanggaran terhadap satu atau beberapa
                  kewajiban Anda berdasarkan perjanjian dengan KISI.
                </p>
                <p>
                  Sehubungan dengan hal tersebut, penundaan atau pembatasan
                  pemrosesan Data Pribadi tersebut mengakibatkan Anda memberikan hak
                  kepada KISI untuk melakukan pemblokiran REN dan/atau RDN Anda,
                  dan/atau menyatakan bahwa utang dan/atau kewajiban Anda kepada
                  KISI menjadi jatuh tempo dan dapat ditagih. Segala kerugian yang
                  timbul akibat pelaksanaan hak Anda untuk menunda atau membatasi
                  pemrosesan Data Pribadi merupakan tanggung jawab Anda.
                </p>
                <li
                  style={{ fontSize: "1.2rem" }}
                  class="monsterratSemiBold blue-text-1"
                >Hak lainnya sesuai peraturan perundang-undangan</li>
                <p>
                  Anda berhak untuk mengajukan hak lainnya terkait pemrosesan Data
                  Pribadi sepanjang diatur dalam peraturan perundang-undangan yang
                  berlaku.
                </p>
              </ol>
              <li
                style={{ fontSize: "1.5rem" }}
                class="monsterratSemiBold blue-text-1"
              >
                {" "}
                <h4 class="monsterratSemiBold blue-text-1">
                  Penyimpanan Data Pribadi
                </h4>
              </li>
              <p>
                KISI melakukan penyimpanan data sesuai dengan ketentuan retensi
                penyimpanan data di KISI. Sebagai contoh, KISI akan menyimpan data
                pribadi Anda dalam jangka waktu tertentu sejak Anda berinteraksi
                dengan KISI. KISI mungkin dapat menyimpan data Anda lebih lama
                dari batas waktu yang ditentukan jika hal tersebut diperlukan
                untuk kebutuhan hukum, membantu mendeteksi kejahatan keuangan,
                menjawab permintaan regulator atau aparat yang berwenang, dan hal
                lainnya. Data pribadi Anda disimpan selama tujuan pengumpulannya
                masih diperlukan untuk tujuan hukum atau kepentingan bisnis
                lainnya. Jika KISI tidak membutuhkan lagi informasi terkait data
                pribadi Anda, KISI dapat menghapus data pribadi Anda sesuai dengan
                ketentuan yang berlaku di KISI.
              </p>
              <li
                style={{ fontSize: "1.5rem" }}
                class="monsterratSemiBold blue-text-1"
              >
                {" "}
                <h4 class="monsterratSemiBold blue-text-1">
                  Pengamanan Data Pribadi
                </h4>
              </li>
              <p>
                KISI menggunakan berbagai cara untuk menjaga Data Pribadi Anda
                tetap aman dan terlindungi, seperti penggunaan teknologi enkripsi
                dan bentuk keamanan lainnya. Di samping itu, KISI juga mewajibkan
                staf KISI dan pihak lain yang bekerja sama dengan KISI untuk patuh
                dan menaati seluruh ketentuan yang berlaku terkait pelindungan
                Data Pribadi dan menerapkan langkah-langkah pengamanan dalam
                melakukan pemrosesan Data Pribadi.
              </p>
              <li
                style={{ fontSize: "1.5rem" }}
                class="monsterratSemiBold blue-text-1"
              >
                {" "}
                <h4 class="monsterratSemiBold blue-text-1">
                  Perubahan Kebijakan Privasi
                </h4>
              </li>
              <p>
                KISI dapat mengubah, melengkapi, dan/atau mengganti Kebijakan
                Privasi ini dari waktu ke waktu untuk memastikan bahwa Kebijakan
                Privasi ini sejalan dengan prosedur dan praktik yang dijalankan
                oleh KISI dalam melakukan pemrosesan Data Pribadi. KISI akan
                menyediakan Kebijakan Privasi terkini melalui <em>website</em>{" "}
                KISI di &nbsp;
                <a href="http://www.kisi.co.id/">www.kisi.co.id</a>.{" "}
                <strong></strong>
              </p>
              <li
                style={{ fontSize: "1.5rem" }}
                class="monsterratSemiBold blue-text-1"
              >
                {" "}
                <h4 class="monsterratSemiBold blue-text-1">Hubungi KISI</h4>
              </li>
              <p>
                Untuk informasi lebih lanjut, Anda dapat menghubungi KISI melalui{' '}
                <em>Call Center</em> 021 2991 1911 atau <em>email</em>{" "}
                <em>Call Center</em> di &nbsp;
                <a href="mailto:cc@kisi.co.id">cc@kisi.co.id</a>.
              </p>
            </ol>

            {/* <p align="left">
              <strong></strong>
            </p>
            <p align="left">
              <strong>A.</strong> <strong>Ruang Lingkup Data Pribadi</strong>
            </p>
            <p>
              Data Pribadi yang dikumpulkan oleh KISI sehubungan dengan
              penyediaan produk dan jasa/layanan, meliputi Data Pribadi yang
              bersifat spesifik dan Data Pribadi yang bersifat umum.
            </p>
            <p>
              <strong>1.</strong>{" "}
              <strong>Data Pribadi yang bersifat spesifik</strong>
            </p>
            <p>Data Pribadi yang bersifat spesifik, antara lain:</p>
            <p>
               Data terik yang memungkinkan identifikasi unik terhadap
              individu, seperti gambar wajah, rekam sidik jari;
            </p>
            <p>
               Data keuangan pribadi, seperti riwayat transaksi, informasi
              saldo efek dan saldo kas pada bank Rekening Dana Nasabah (”
              <strong>RDN</strong>”); dan
            </p>
            <p>
               Data lainnya sesuai dengan ketentuan peraturan
              perundang-undangan.
            </p>
            <p>
              <strong>2.</strong>{" "}
              <strong>Data Pribadi yang bersifat umum</strong>
            </p>
            <p>Data Pribadi yang bersifat umum, antara lain:</p>
            <p> Nama lengkap;</p>
            <p> Jenis kelamin;</p>
            <p> Kewarganegaraan;</p>
            <p> Agama;</p>

            <p> Status perkawinan;</p>
            <p> Alamat;</p>

            <p> Tempat dan tanggal lahir;</p>
            <p> Nama gadis ibu kandung;</p>
            <p> Pekerjaan;</p>

            <p>
               Data pribadi yang dikombinasikan untuk mengidentifikasi
              seseorang, seperti nomor telepon seluler dan&nbsp; <em>IP Address.</em>{" "}
            </p>
            <p align="left">
              <strong>B.</strong>
              <strong>
                Bagaimana KISI Memperoleh dan Mengumpulkan Data Pribadi
              </strong>
            </p>
            <p>
              KISI memperoleh dan mengumpulkan Data Pribadi melalui berbagai
              sarana dan aktivitas antara lain:
            </p>
            <p>
              <strong>1.</strong>
              <strong>
               &nbsp; <em>Website</em>
              </strong>
              <strong>, Aplikasi, dan Formulir</strong>
            </p>
            <p>
              KISI dapat memperoleh dan mengumpulkan Data Pribadi dari perangkat
              yang Anda gunakan pada saat mengakses&nbsp; <em>website</em> atau
              aplikasi yang disediakan oleh KISI, dan dari permohonan layanan
              perbankan yang Anda ajukan baik dengan mengisi formulir,
              melengkapi data permohonan melalui
             &nbsp; <em>website</em> , aplikasi KISI, dan media lainnya yang
              disediakan oleh KISI maupun pihak yang bekerja sama dengan KISI.
            </p>
            <p>
              <strong>2.</strong>{" "}
              <strong>
                Komunikasi dan&nbsp; <em>Interaksi</em>
              </strong>
            </p>
            <p>
              KISI dapat memperoleh dan mengumpulkan Data Pribadi saat Anda
              melakukan komunikasi dan interaksi dengan KISI, antara lain pada
              saat Anda menghubungi
             &nbsp; <em>Call Center</em> KISI, menjawab pertanyaan, memberikan
              informasi atau masukan, atau berpartisipasi dalam promosi, kontes,
              survei, atau konferensi yang diselenggarakan oleh KISI.
            </p>
            <p>
              <strong>3.</strong>{" "}
              <strong>
                Aktivitas&nbsp; <em>Transaksi</em>
              </strong>
            </p>
            <p>
              KISI mengumpulkan informasi atas aktivitas transaksi Anda saat
              Anda dan/atau perwakilan resmi Anda melakukan transaksi dengan
              atau melalui sarana yang disediakan oleh KISI.
            </p>
            <p>
              <strong>4.</strong>
              <strong>
               &nbsp; <em>Media</em>
              </strong>
              <strong>
                Sosial dan Forum&nbsp; <em>Online</em>
              </strong>
            </p>
            <p>
              KISI dapat memperoleh dan mengumpulkan Data Pribadi melalui
             &nbsp; <em>platform</em> dan situs media sosial KISI yang Anda gunakan
              atau melalui forum
             &nbsp; <em>online</em> KISI yang Anda akses.
            </p>
            <p>
              <strong>5.</strong>
              <strong>
               &nbsp; <em>Lokasi</em>
              </strong>{" "}
              <strong>Fisik</strong>
            </p>
            <p>
              KISI dapat memperoleh dan mengumpulkan Data Pribadi baik secara
              langsung maupun tidak langsung, ketika Anda dan/atau perwakilan
              resmi Anda mengunjungi cabang, kantor, dan lokasi fisik lainnya
              yang berkaitan dengan KISI.
            </p>
            <p>
              <strong>6.</strong>{" "}
              <strong>
                Afiliasi dan&nbsp; <em>Pihak</em> Ketiga
              </strong>
            </p>
            <p>
              KISI dapat memperoleh dan mengumpulkan Data Pribadi dari pihak
              terafiliasi KISI atau pihak lainnya yang bekerja sama dengan KISI.
            </p>
            <p align="left">
              <strong>C.</strong>{" "}
              <strong>Tujuan Pemrosesan Data Pribadi</strong>
            </p>
            <p>
              KISI akan melakukan pemrosesan atas Data Pribadi yang diperoleh
              maupun dikumpulkan oleh KISI untuk tujuan antara lain:
            </p>
            <p>
              1. Penyediaan layanan dan/atau jasa terkait produk pasar modal
              KISI bagi nasabah;
            </p>
            <p>
              2. Menjalankan prinsip&nbsp; <em>Customer Due Dilligence</em> (CDD)
              sesuai ketentuan hukum yang berlaku;
            </p>
            <p>
              3. Memberikan personalisasi layanan dan/atau jasa terkait produk
              pasar modal yang KISI sediakan untuk Anda;
            </p>
            <p>
              4. Kepatuhan terhadap ketentuan hukum yang berlaku termasuk namun
              tidak terbatas pada Anti Pencucian Uang, Pencegahan Pendanaan
              Terorisme, dan Pencegahan Pendanaan Proliferasi Senjata Pemusnah
              Massal (APU-PPT dan PPPSPM);
            </p>
            <p>
              5. Menyelesaikan setiap permasalahan terkait akses Anda terhadap
              layanan dan/atau jasa terkait produk pasar modal KISI;
            </p>
            <p>
              6. Menindaklanjuti setiap keluhan atas layanan dan/atau jasa
              terkait produk pasar modal KISI atau pihak lain yang bekerja sama
              dengan KISI;
            </p>
            <p>
              7. Evaluasi, peningkatan, dan/atau pembaruan fitur layanan
              dan/atau jasa terkait produk pasar modal KISI dari waktu ke waktu;
            </p>
            <p>
              <a name="_Hlk179536693">
                8. Penawaran iklan/promosi maupun pemasaran atas layanan
                dan/atau jasa terkait produk pasar modal KISI atau pihak lain
                yang bekerja sama dengan KISI dan penyelenggaraan loyalty
                program, kepada nasabah seperti gift, undian, dan lainnya; dan
              </a>
            </p>
            <p>
              9. Tujuan-tujuan lainnya selama diizinkan atau diwajibkan oleh
              ketentuan hukum yang berlaku.
            </p>
            <p align="left">
              <strong></strong>
            </p>
            <p align="left">
              <strong>D.</strong> <strong>Pengungkapan Data Pribadi</strong>
            </p>
            <p>
              KISI dapat mengungkapkan Data Pribadi Anda kepada afiliasi KISI
              atau pihak lainnya yang bekerja sama dengan KISI, baik yang
              berlokasi di Indonesia atau di negara lain, untuk melaksanakan
              tujuan pemrosesan Data Pribadi sebagaimana dimaksud dalam
              ketentuan Kebijakan Privasi ini. Dalam hal KISI mengungkapkan Data
              Pribadi Anda kepada afiliasi KISI atau pihak lainnya yang bekerja
              sama dengan KISI, KISI akan meminta kepada afiliasi KISI atau
              pihak lainnya yang bekerja sama dengan KISI untuk menjaga
              kerahasiaan dan keamanan atas Data Pribadi Anda sesuai dengan
              ketentuan yang berlaku.
            </p>
            <p align="left">
              <strong></strong>
            </p>
            <p align="left">
              <strong>E.</strong> <strong>Hak Subjek Data Pribadi</strong>
            </p>
            <p align="left">
              <strong>1.</strong> <strong>Hak atas Informasi dan Akses</strong>
            </p>
            <p>
              Anda memiliki hak untuk memperoleh informasi mengenai identitas
              pihak yang meminta Data Pribadi Anda, tujuan permintaannya, serta
              akses terhadap salinan Data Pribadi Anda. KISI akan memberikan
              akses ke informasi tersebut melalui sarana resmi KISI, seperti
              cabang KISI atau&nbsp; <em>channel</em> lainnya, sesuai dengan ketentuan
              peraturan perundang-undangan dan kebijakan KISI.
            </p>
            <p align="left">
              <strong>2.</strong> <strong>Hak atas Perbaikan Data</strong>
            </p>
            <p>
              Anda memiliki hak untuk melengkapi, memperbarui, dan/atau
              memperbaiki Data Pribadi yang salah atau tidak akurat.
            </p>
            <p align="left">
              <strong>3.</strong>
              <strong>
                Hak untuk Mendapatkan, Menggunakan dan/atau Mengirimkan Data
                Pribadi ke Pihak Lain
              </strong>
            </p>
            <p>
              Anda memiliki hak untuk memperoleh, memanfaatkan, atau memberikan
              Data Pribadi Anda yang ada pada KISI kepada pihak ketiga, selama
              sistem komunikasi yang digunakan oleh KISI dan Pihak Ketiga yang
              dimaksud aman.
            </p>
            <p align="left">
              <strong>4.</strong>
              <strong>
                Hak untuk Mengakhiri Pemrosesan, Menghapus dan/atau Memusnahkan
                Data Pribadi
              </strong>
            </p>
            <p>
              Anda berhak untuk mengakhiri pemrosesan, menghapus dan/atau
              memusnahkan Data Pribadi Anda. Anda setuju untuk memberikan KISI
              waktu untuk memproses pengakhiran pemrosesan, penghapusan dan/atau
              pemusnahan Data Pribadi Anda sejauh KISI perlukan. Untuk
              menjalankan hak mengakhiri pemrosesan, penghapusan dan/atau
              pemusnahan Data Pribadi tersebut, Anda dapat menghubungi KISI
              melalui sarana komunikasi yang diatur dalam Kebijakan Privasi ini.
            </p>
            <p>
              Untuk dipahami, pengakhiran pemrosesan, penghapusan dan/atau
              pemusnahan Data Pribadi tersebut dapat mempengaruhi kemampuan KISI
              untuk menyediakan produk, layanan, dan jasa kepada Anda serta
              hubungan kontraktual yang telah dibuat antara KISI dengan Anda
              ataupun antara KISI dengan pihak ketiga lainnya, termasuk dapat
              mengakibatkan terhentinya layanan yang Anda terima dan/atau
              terjadinya pengakhiran atas satu atau beberapa perjanjian Anda
              dengan KISI dan/atau pelanggaran terhadap satu atau beberapa
              kewajiban Anda berdasarkan perjanjian dengan KISI.
            </p>
            <p>
              Sehubungan dengan hal tersebut, pengakhiran pemrosesan,
              penghapusan dan/atau pemusnahan Data Pribadi mengakibatkan Anda
              memberikan hak kepada KISI untuk melakukan pemblokiran Rekening
              Efek Nasabah (”<strong>REN</strong>”) dan/atau RDN Anda, dan/atau
              menyatakan bahwa utang dan/atau kewajiban Anda kepada KISI menjadi
              jatuh tempo dan dapat ditagih. Segala kerugian yang timbul akibat
              pelaksanaan hak Anda untuk mengakhiri pemrosesan, penghapusan
              dan/atau pemusnahan Data Pribadi merupakan tanggung jawab Anda.
            </p>
            <p>
              Kewajiban KISI untuk menghapus dan memusnahkan Data Pribadi Anda
              dikecualikan untuk:
            </p>
            <p align="left"> Kepentingan pertahanan dan keamanan nasional;</p>
            <p align="left"> Kepentingan proses penegakan hukum;</p>
            <p align="left">
               Kepentingan umum dalam rangka penyelenggaraan negara; atau
            </p>
            <p align="left">
               Kepentingan pengawasan sektor jasa keuangan, moneter, sistem
              pembayaran, dan stabilitas sistem keuangan yang dilakukan dalam
              rangka penyelenggaraan negara. <br />
              <br />
            </p>
            <p align="left">
              <strong>5.</strong> <strong>Hak untuk Menarik Persetujuan</strong>
            </p>
            <p>
              Anda berhak untuk menarik kembali persetujuan pemrosesan Data
              Pribadi yang telah Anda berikan kepada KISI, dan Anda setuju untuk
              memberikan KISI tambahan waktu untuk memproses pengakhiran
              pemrosesan Data Pribadi Anda sejauh KISI perlukan. Untuk
              menjalankan hak penarikan persetujuan tersebut, Anda dapat
              menghubungi KISI melalui sarana komunikasi yang diatur dalam
              Kebijakan Privasi ini.
            </p>
            <p>
              Anda perlu memahami bahwa penarikan persetujuan tersebut dapat
              mempengaruhi kemampuan KISI untuk menyediakan produk, layanan, dan
              jasa kepada Anda serta mengelola hubungan kontraktual yang telah
              dibuat antara KISI dengan Anda ataupun antara KISI dengan pihak
              ketiga lainnya termasuk dapat mengakibatkan terhentinya layanan
              yang Anda terima dan/atau terjadinya pengakhiran atas satu atau
              beberapa perjanjian Anda dengan KISI dan/atau pelanggaran terhadap
              satu atau beberapa kewajiban Anda berdasarkan perjanjian dengan
              KISI.
            </p>
            <p>
              Sehubungan dengan hal tersebut, penarikan persetujuan pemrosesan
              Data Pribadi tersebut mengakibatkan Anda memberikan hak kepada
              KISI untuk melakukan pemblokiran REN dan/atau RDN, dan/atau
              menyatakan bahwa utang dan/atau kewajiban Anda kepada KISI menjadi
              jatuh tempo dan dapat ditagih. Segala kerugian yang timbul akibat
              pelaksanaan hak Anda untuk menarik persetujuan pemrosesan Data
              Pribadi merupakan tanggung jawab Anda.
            </p>
            <p align="left">
              <strong>6.</strong>
              <strong>
                Hak Mengajukan Keberatan atas Hasil Pemrosesan Otomatis
              </strong>
            </p>
            <p>
              Anda berhak mengajukan keberatan atas hasil pemrosesan Data
              Pribadi Anda yang dilakukan secara otomatis yang menimbulkan
              akibat hukum atau berdampak signifikan terhadap Anda, termasuk
              pemprofilan dan/atau penilaian resiko profil Anda.
            </p>
            <p align="left">
              <strong>7.</strong>
              <strong>Hak untuk Menunda atau Membatasi Pemrosesan</strong>
            </p>
            <p>
              Anda berhak untuk menunda atau membatasi pemrosesan Data Pribadi
              Anda secara proporsional sesuai dengan tujuan pemrosesan Data
              Pribadi Anda. Untuk pelaksanaan hak ini, Anda dapat menghubungi
              KISI melalui sarana komunikasi yang diatur dalam Kebijakan Privasi
              ini. Anda perlu memahami bahwa permintaan penundaan atau
              pembatasan pemrosesan tersebut dapat mempengaruhi kemampuan KISI
              untuk menyediakan produk, layanan, dan jasa kepada Anda, serta
              hubungan kontraktual yang telah dibuat antara KISI dengan Anda
              ataupun antara KISI dengan pihak ketiga lainnya termasuk dapat
              mengakibatkan terhentinya layanan yang Anda terima dan/atau
              terjadinya pengakhiran atas satu atau beberapa perjanjian Anda
              dengan KISI dan/atau pelanggaran terhadap satu atau beberapa
              kewajiban Anda berdasarkan perjanjian dengan KISI.
            </p>
            <p>
              Sehubungan dengan hal tersebut, penundaan atau pembatasan
              pemrosesan Data Pribadi tersebut mengakibatkan Anda memberikan hak
              kepada KISI untuk melakukan pemblokiran REN dan/atau RDN Anda,
              dan/atau menyatakan bahwa utang dan/atau kewajiban Anda kepada
              KISI menjadi jatuh tempo dan dapat ditagih. Segala kerugian yang
              timbul akibat pelaksanaan hak Anda untuk menunda atau membatasi
              pemrosesan Data Pribadi merupakan tanggung jawab Anda.
            </p>
            <p align="left">
              <strong>8.</strong>
              <strong>Hak lainnya sesuai peraturan perundang-undangan</strong>
            </p>
            <p>
              Anda berhak untuk mengajukan hak lainnya terkait pemrosesan Data
              Pribadi sepanjang diatur dalam peraturan perundang-undangan yang
              berlaku.
            </p>
            <p align="left">
              <strong>F.</strong> <strong>Penyimpanan Data Pribadi</strong>
            </p>
            <p>
              KISI melakukan penyimpanan data sesuai dengan ketentuan retensi
              penyimpanan data di KISI. Sebagai contoh, KISI akan menyimpan data
              pribadi Anda dalam jangka waktu tertentu sejak Anda berinteraksi
              dengan KISI. KISI mungkin dapat menyimpan data Anda lebih lama
              dari batas waktu yang ditentukan jika hal tersebut diperlukan
              untuk kebutuhan hukum, membantu mendeteksi kejahatan keuangan,
              menjawab permintaan regulator atau aparat yang berwenang, dan hal
              lainnya. Data pribadi Anda disimpan selama tujuan pengumpulannya
              masih diperlukan untuk tujuan hukum atau kepentingan bisnis
              lainnya. Jika KISI tidak membutuhkan lagi informasi terkait data
              pribadi Anda, KISI dapat menghapus data pribadi Anda sesuai dengan
              ketentuan yang berlaku di KISI.
            </p>
            <p align="left">
              <strong>G.</strong> <strong>Pengamanan Data Pribadi</strong>
            </p>
            <p>
              KISI menggunakan berbagai cara untuk menjaga Data Pribadi Anda
              tetap aman dan terlindungi, seperti penggunaan teknologi enkripsi
              dan bentuk keamanan lainnya. Di samping itu, KISI juga mewajibkan
              staf KISI dan pihak lain yang bekerja sama dengan KISI untuk patuh
              dan menaati seluruh ketentuan yang berlaku terkait pelindungan
              Data Pribadi dan menerapkan langkah-langkah pengamanan dalam
              melakukan pemrosesan Data Pribadi.
            </p>
            <p align="left">
              <strong>H.</strong> <strong>Perubahan Kebijakan Privasi</strong>
            </p>
            <p>
              KISI dapat mengubah, melengkapi, dan/atau mengganti Kebijakan
              Privasi ini dari waktu ke waktu untuk memastikan bahwa Kebijakan
              Privasi ini sejalan dengan prosedur dan praktik yang dijalankan
              oleh KISI dalam melakukan pemrosesan Data Pribadi. KISI akan
              menyediakan Kebijakan Privasi terkini melalui&nbsp; <em>website</em>{" "}
              KISI di
              <a href="http://www.kisi.co.id/">www.kisi.co.id</a>.{" "}
              <strong></strong>
            </p>
            <p align="left">
              <strong>I.</strong> <strong>Hubungi KISI</strong>
            </p>
            <p>
              Untuk informasi lebih lanjut, Anda dapat menghubungi KISI melalui
             &nbsp; <em>Call Center</em> 021 2991 1911 atau&nbsp; <em>email</em>{" "}
             &nbsp; <em>Call Center</em> di
              <a href="mailto:cc@kisi.co.id">cc@kisi.co.id</a>.
            </p> */}
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(document.documentElement.scrollTop,path);
    // switch (page) {
    //   case "disclaimer":
    //     window.scrollTo(0, 110);
    //     //  discRef.current.scrollIntoView({block:'center'})
    //     break;
    //   case "kebijakan_privasi":
    //     window.scrollTo(0, 900);
    //     // kebRef.current.scrollIntoView({block:'nearest'})
    //     break;
    //   case "informasi":
    //     window.scrollTo(0, 2550);
    //     // infoRef.current.scrollIntoView({block:'center'})
    //     break;
    //   case "margin":
    //     return <DisclaimerMargin />;

    //   default:
    //     break;
    // }
    // switch (path) {
    //   case "/privacy":
    //     window.scrollTo(0, 900);
    //     //  discRef.current.scrollIntoView({block:'center'})
    //     break;

    //   default:
    //     break;
    // }
  }, [page, state]);
  return <GeneralDisclaimer />;
}
